import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
const Unauthorized = () => {
  const navigate = useNavigate();
  const logout = () => {
    sessionStorage.removeItem("jwt");
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("username");
    sessionStorage.removeItem("password");
    localStorage.removeItem("userInfo");
    navigate("/auth");
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "fixed",
        top: "45%",
        left: "50%",
        transform: "translate(-50%,-50%)",
      }}
    >
      <p>Vous n'êtes pas autorisé à accéder au contenu de ce site</p>
      <Button
        variant="contained"
        color="error"
        margin="normal"
        onClick={logout}
      >
        Sortir
      </Button>
    </div>
  );
};

export default Unauthorized;
