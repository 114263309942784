import * as config from "../config/config.js";
import axiosClient from "../api/axio.client.js";

export const getAllJokes = async () => {
  try {
    const response = await axiosClient.get(`${config.baseUrl}/jokes/get-all`);
    return response.data;
  } catch (error) {
    return [];
  }
};

export const getJoke = async (id) => {
  try {
    const response = await axiosClient.get(
      `${config.baseUrl}/jokes/get-by-id/${id}`
    );
    return response.data.data;
  } catch (error) {
    return error;
  }
};

export const createJoke = async (question, answer) => {
  try {
    const response = await axiosClient.post(
      `${config.baseUrl}/jokes/create`,
      { question: question, answer: answer },
      {
        headers: {
          "Cache-Control": "no-cache",
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    return [];
  }
};

export const updateJoke = async (question, answer, id) => {
  try {
    const response = await axiosClient.put(
      `${config.baseUrl}/jokes/update`,
      { question: question, answer: answer, id: id },
      {
        headers: {
          "Cache-Control": "no-cache",
        },
      }
    );
    return response.data;
  } catch (error) {
    return [];
  }
};

export const deleteJoke = async (id) => {
  try {
    const response = await axiosClient.delete(
      `${config.baseUrl}/jokes/delete/${id}`
    );
    return response;
  } catch (error) {
    return [];
  }
};
