import "./index.css";
import { useState } from "react";
import * as ingredientsService from "../../../services/ingredients";
import BackButton from "../../../components/backButton";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Title from "../../../components/title";
import { useNavigate } from "react-router";

const CreateIngredient = () => {
  const navigate = useNavigate();

  const [ingredientName, setIngredient] = useState("");
  const title = "Create Ingredient";

  const handleSubmit = async (event) => {
    event.preventDefault();
    await ingredientsService.createIngredient(ingredientName);
    navigate("/Ingredients");
  };

  return (
    <div className="container">
      <div className="div1">
        <BackButton />
      </div>
      <div className="div2">
        <Title title={title} />
        <form className=" form shadow" onSubmit={handleSubmit}>
          <TextField
            label="Ingredient"
            variant="outlined"
            margin="normal"
            value={ingredientName}
            onChange={(e) => setIngredient(e.target.value)}
          />

          <Button
            variant="contained"
            color="success"
            type="submit"
            margin="normal"
          >
            Valider
          </Button>
        </form>
      </div>
    </div>
  );
};

export default CreateIngredient;
