import './index.css';
import React from 'react';
import { side_bar_data } from './side_bar_data.js';
import Image from '../logo';
import "@fontsource/amatic-sc";
import { useNavigate } from 'react-router';
function SideBar() {
    const navigate = useNavigate();
    return (

        <div className="Sidebar">
            <Image />
            <h5 >TROUVE,SIMPLEMENT,QUOI MANGER</h5>
            <ul className="SidebarList">
                {side_bar_data.map((val, key) => {
                    return (
                        <li
                            key={key}
                            className="row"
                            onClick={() => {
                                navigate(val.link)
                            }}
                        >
                            <div id="icon">{val.icon}</div> <div id="title">{val.title}</div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}
export default SideBar;