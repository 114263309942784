import React from "react";
import "./index.css";
import NavBar from "../../components/nav-bar";
import SideBar from "../../components/side-bar";
import ChartUser from "../../components/Statistique/Utilisateur/ChartUser";
import TotalIngredient from "../../components/Statistique/Ingredient/TotalIngredient";
import TotalRecipe from "../../components/Statistique/Recipe/TotalRecipe";
import StatCategorie from "../../components/Statistique/Recipe/StatCategorie";
import StatType from "../../components/Statistique/Recipe/StatType";
import TotalJokes from "../../components/Statistique/Joke/TotalJokes";
import StatMultifiltre from "../../components/Statistique/Recipe/StatMultifiltre";

const Home = () => {
  return (
    <div className="page">
      <SideBar />
      <div>
        <div className="nav-bar-content-container">
          <NavBar />
          <div className="content">
            <div className="nbr-total">
              <div className="total" style={{ width: 200 }}>
                <ChartUser />
              </div>
              <div className="total" style={{ width: 200 }}>
                <TotalIngredient />
              </div>
              <div className="total" style={{ width: 200 }}>
                <TotalRecipe />
              </div>
              <div className="total" style={{ width: 200 }}>
                <TotalJokes />
              </div>
            </div>

            <div className="recipes">
              <div className="title">Statistique sur les recettes</div>
              <div className="state">
                <div className="recette" style={{ width: 400 }}>
                  <StatType />
                </div>
                <div className="recette" style={{ width: 400 }}>
                  <StatCategorie />
                </div>
              </div>
            </div>

            <div>
              <div>
                <StatMultifiltre />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
