import './index.css';
import React, { useState } from 'react';
import { useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import { Pagination } from '@mui/material';
import * as recipeServices from "../../services/recipes";
import swal from 'sweetalert';
import { Button } from '@mui/material';
// import { baseUrl } from "../../config/config"
// import axiosClient from '../../api/axios.file';
import TextField from '@mui/material/TextField';

const Tablerecipe = (props) => {
  const headers = JSON.parse(localStorage.getItem("userInfo")) && JSON.parse(localStorage.getItem("userInfo")).isAdmin === 1 ?
                  ['Check', 'Id', 'Recipe Name','Recipe Type']:
                  ['Id', 'Recipe Name', 'Recipe Type'];
                  
  const [recipe, setData] = useState(props.recipe);
  // const [Recipe_name, setRecipe_name] = useState(props.recipe_name);

  // const [checkedItems, setCheckedItems] = useState(0);
  // const [items, setItems] = useState([]);

  let [countCheckedItems, setCountCheckedItems] = useState(0);
  // const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(9);
  
  const [recipeChecked, setRecipeChecked] = useState([])


  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = props.recipe.slice(indexOfFirstItem, indexOfLastItem);

  const [search, setSearch] = useState('');

  useEffect(() => {
    setCountCheckedItems(recipe.filter(item => item.checked === true).length);
  }, [recipe]);


  const handleInputChange = (e) => {
    const {value, checked} = e.target
    if(checked){
      setRecipeChecked(pre => [...pre, parseInt(value)])
    }
    else{
      setRecipeChecked(pre => {
        return [...pre.filter(item => item !== parseInt(value))]
      })
    }
    // console.log(recipeChecked)
  }

  const handleDelete = async (id, name) => {
    swal({
      title: "Are you sure?",
      text: `${name} sera supprimé définitivement`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then( async function (isOkay) {
      if (isOkay) {
          recipeServices.deleteRecipe(id)
          .then(res => {
            if(res.status === 200){
              swal("Recette supprimé!", "Suppression avec succès", "success");
              // axiosClient.post(`${baseUrl}/uploads/${id}`);
              setTimeout(()=>{window.location.reload(false)},2000);
              }
          })
          .catch(err => {
            swal("Nope", "Une erreur s'est produite", "error");
            throw new Error(err)
          })
      }
  });
  };

  const handleDeleteAll = () => {
    swal({
      title: "Are you sure?",
      text: `Ces recettes seront supprimés définitivement`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then( async function (isOkay) {
      if (isOkay) {
        recipeChecked.forEach(recipe => {
          recipeServices.deleteRecipe(recipe)
          .then(res => {
            if(res.status === 200){
              swal(`Recettes supprimés!`, `Suppression des recettes avec succès!`, "success");
              setTimeout(()=>{window.location.reload(false)},500);
              }
          })
          .catch(err => {
            swal("Nope", "Une erreur s'est produite", "error");
            throw new Error(err)
          })
        })
      }
  });
  };
  
  const handleChange = (event, value) => {
    setCurrentPage(value);
  };
  
  return (
    <>
    {recipeChecked.length >= 2 ? <div className='buttonContainer m-1'>
            <Button variant="contained" color="error" margin='normal' onClick={handleDeleteAll}>
                Suppression multiple
            </Button>
      </div>:null}
    <div id="tableIngredientsPage">
    <TextField
        id="search-bar"
        className="text"
        onInput={(e) => {
          setCurrentPage(e.target.value);
        }}
        label="page number"
        variant="outlined"
        size="small"
        value={currentPage}
        style={{marginBottom:'10px'}}
      />
      <table className='table1'>
        <thead>
          <tr className='tb1'>
            {headers.map(head => <th>{head}</th>)}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item) => (
            <tr key={item.id}>
              {JSON.parse(localStorage.getItem("userInfo")) && JSON.parse(localStorage.getItem("userInfo")).isAdmin === 1 ? <td>
                <input type="checkbox" value={item.id} checked={item.checked} onChange={handleInputChange}  />
              </td>:
              <></>}
              <td>{item.id}</td>
              <td>{item.recipe_name}</td>
              <td>{item.recipe_type}</td>
              {JSON.parse(localStorage.getItem("userInfo")) && JSON.parse(localStorage.getItem("userInfo")).isAdmin === 1 ?<td className='btn2'>
                <Link className='btn' to={`edit/${item.id}`}><EditIcon /></Link>
                <button className='btn' onClick={() => handleDelete(item.id, item.recipe_name)}><DeleteIcon /></button>
              </td>:
              <></>}
            </tr>
          ))}
        </tbody>
      </table>
      {countCheckedItems > 1 && (
        <button onClick={handleDeleteAll}>Delete All</button>
      )}
      <Pagination className='pagi'
        count={Math.ceil(props.recipe.length / itemsPerPage)}
        page={currentPage}
        variant="outlined"
        shape='rounded'
        onChange={handleChange}
        style={{display:"flex"}}
      />
    </div>
    </>
  );
  
};

export default Tablerecipe;