import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as userServices from '../services/users';

const userContext = createContext();

const UserProvider = ({ children }) => {
  const [token, setToken] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const jwt = sessionStorage.getItem("jwt");
    setToken(jwt);
    if (!jwt) {
      navigate("/auth") 
    };
  }, [navigate, token]);

  // const [isAuth, setIsAuth] = useState()

    useEffect(()=>{
        const blocked = JSON.parse(localStorage.getItem('userInfo'))
        if(blocked){
          if(blocked.blocked === 1)
          navigate("/unauthorized") 
        }
    },[navigate])

  useEffect(() => {
    const interval = setInterval(() => {
        const username = sessionStorage.getItem("username")
        const userPassword = sessionStorage.getItem("password")
        if(username && userPassword){
           userServices.login(username, userPassword)
        .then(res => console.log(res ? "Token refreshed" : "Not authorized"))
        }
    }, 600000);
    return () => clearInterval(interval);
  }, []);
  
  return (
    <userContext.Provider
      value={{
        token,
        setToken,
      }}
    >
      {children}
    </userContext.Provider>
  );
};

export const ChatState = () => {
  return useContext(userContext);
};

export default UserProvider;