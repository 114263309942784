import React from "react";
import HomeIcon from '@mui/icons-material/Home';
import EventNoteIcon from '@mui/icons-material/EventNote';
import PeopleIcon from '@mui/icons-material/People';
import  KitchenIcon from '@mui/icons-material/Kitchen';
import TagFacesIcon from '@mui/icons-material/TagFaces';

export const side_bar_data=[
{
   title:"Home",
    icon:<HomeIcon/>,
   link:"/"
},
{
   title:"Users",
   icon:<PeopleIcon/>,
   link:"/Users"
},
{
   title:"Recipes",
   icon:<EventNoteIcon/>,
   link:"/Recipes"
},
{
   title:"Ingredients",
   icon:<KitchenIcon/>,
   link:"/Ingredients"
},
{
   title:"Jokes",
   icon:<TagFacesIcon/>,
   link:"/Jokes"
}
]
