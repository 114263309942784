import "./index.css";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Switch from "@mui/material/Switch";
import BackButton from "../../../components/backButton";
import { useNavigate } from "react-router-dom";
import Title from "../../../components/title";
import { Button } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import * as userServices from "../../../services/users";
import swal from "sweetalert";

export const CreateUser = () => {
  const title = "Create new User";
  const navigate = useNavigate();
  const location = useLocation(); 

  const { item = {}, isEditMode } = location.state || {};
  const [username, setUsername] = useState(item.username);
  const [email, setEmail] = useState(item.email);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [blocked, setBlocked] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    //on teste si le mdp==mdp confirmé
    // var password = document.getElementById("passwordInput").value;
    // var confirm_password = document.getElementById("confirmPasswordInput").value;

    if (!username || !email || !password || !confirmPassword) {
      swal("Nope", "Veuillez remplir toutes les cases!", "error");
    } else if (password !== confirmPassword) {
      swal("Nope", "Veuillez indiquer les 2 mots de passe identiques", "error");
    } else {
      userServices
        .createUser(username, email, password, isAdmin, confirmed, blocked)
        .then((res) => {
          if (res.data.status === 200) {
            swal(
              "Utilisateur crée!",
              "Création d'utilisateur avec succès",
              "success"
            );
            setTimeout(() => {
              navigate("/Users");
            }, 2000);
          }
        })
        .catch((err) => {
          swal("Nope", "Une erreur s'est produite", "error");
          throw new Error(err);
        });
    }
  };

  return (
    <div>
      <div className="back">
        <BackButton />
      </div>
      <div className="width">
        <Title title={title} />
        <form onSubmit={handleSubmit} className=" forme shadow p-1 ">
          <div className="row">
            <div className="texte">
              <TextField
                type="text"
                label="Username"
                variant="outlined"
                margin="normal"
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="texte">
              <TextField
                type={showPassword ? "text" : "password"}
                label="Password"
                variant="outlined"
                margin="normal"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="texte">
              <TextField
                type="email"
                label="E-mail"
                variant="outlined"
                margin="normal"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="texte">
              <TextField
                type={showConfirmPassword ? "text" : "password"}
                label="Confirm Password"
                variant="outlined"
                margin="normal"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="mt-2 ">
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  label="Confirmed"
                  value={confirmed}
                  labelPlacement="start"
                  control={<Switch color="primary" />}
                  onChange={(e) => setConfirmed(!confirmed)}
                />
                <FormControlLabel
                  label="Blocked"
                  value={blocked}
                  labelPlacement="start"
                  control={<Switch color="primary" />}
                  onChange={(e) => setBlocked(!blocked)}
                />
                <FormControlLabel
                  label="Admin"
                  value={isAdmin}
                  labelPlacement="start"
                  control={<Switch color="primary" />}
                  onChange={(e) => setIsAdmin(!isAdmin)}
                />
              </FormGroup>
            </FormControl>
          </div>
          <div className="mt-2">
            <Button
              variant="contained"
              color="success"
              type="submit"
              margin="normal"
            >
              Valider
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default CreateUser;
