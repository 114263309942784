import React from "react";
import CreateNewItemButton from "../../../components/createButton";
import Title from "../../../components/title";
import TableUsers from "../../../components/table/users";
import * as userServise from "../../../services/users";
import { useState } from "react";
import { useEffect } from "react";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const ShowUsers = () => {
  const title = "Users";
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  useEffect(() => {
    userServise.getAllUsers().then((users) => {
      // if(users) console.log(users)
      setUsers(users);
    });
  }, []);
  return (
    <div>
      <Title title={title} />
      <div>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <TextField
            id="input-with-sx"
            label="Search"
            variant="standard"
            style={{ marginBottom: "10px" }}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Box>
      </div>
      {JSON.parse(localStorage.getItem("userInfo")) &&
      JSON.parse(localStorage.getItem("userInfo")).isAdmin === 1 ? (
        <CreateNewItemButton title="+Add new User" />
      ) : (
        <></>
      )}
      <TableUsers
        user={users.filter((item) =>
          item.username.toLowerCase().includes(search.toLowerCase())
        )}
      />
    </div>
  );
};
export default ShowUsers;
