import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import * as recipeServices from "../../../services/recipes";
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend} from 'chart.js';

ChartJS.register( CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

const StatCategorie = () => {
  const [nbrVegan, setNbrVegan] = useState([]);
  const [nbrFour, setNbrFour] = useState([]);
  const [nbrLactose, setNbrLactose] = useState([]);
  const [nbrGluten, setNbrGluten] = useState([]);

  useEffect(()=>{
    recipeServices.getAllRecipes()
    .then( recipes => {
      const vegan = recipes.filter(recipe => recipe.vegan === 1);
      const four = recipes.filter(recipe => recipe.four === 1);
      const lactose = recipes.filter(recipe => recipe.lactose === 1);
      const gluten = recipes.filter(recipe => recipe.gluten === 1);
      setNbrVegan(vegan);
      setNbrFour(four);
      setNbrLactose(lactose);
      setNbrGluten(gluten)
    })
  }, []);

  const data = {
    labels: ['Four', 'Vegan', 'Gluten', 'Lactose'],
    datasets: [
      {
        label: "Catégorie des recettes",
        data: [nbrFour && nbrFour.length, nbrVegan && nbrVegan.length, nbrGluten && nbrGluten.length, nbrLactose && nbrLactose.length],
        backgroundColor: [
          "#983400",
          "#AF601A",
          "#e73119",
          "#F39C12",
        ]
      },
    ],
  };

  return (
    <div>
        <Bar data={data} />
    </div>
    );
}

export default StatCategorie;