import React, { useEffect, useState } from "react";
import * as recipeServices from "../../../services/recipes";

const TotalRecipe = () => {
  const [nbrRecipe, setNbrRecipe] = useState();

  useEffect(()=>{
    recipeServices.getAllRecipes()
    .then(recipes => {
      setNbrRecipe(recipes.length)
    })
    console.log(nbrRecipe)
  }, [nbrRecipe])

  return (
    <div className=" circle text-white text-center p-2">
      <div className="my-5">
      <h2>Recettes</h2>
      <h1 className='display-5'>{nbrRecipe}</h1>
      </div>
    </div>
    );
}

export default TotalRecipe;