import React from "react";
import CreateNewItemButton from "../../../components/createButton";
import Title from "../../../components/title";
import TableJokes from "../../../components/table/jokes";
import * as jokesService from "../../../services/jokes";
import { useState } from "react";
import { useEffect } from "react";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const ShowJokes = () => {
  const title = "Jokes";
  const [joke, setJokes] = useState([]);
  const [search, setSearch] = useState("");
  useEffect(() => {
    jokesService.getAllJokes().then((jokes) => {
      setJokes(jokes);
    });
  }, []);
  return (
    <div>
      <Title title={title} />
      <div>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <TextField
            id="input-with-sx"
            label="Search"
            variant="standard"
            style={{ marginBottom: "10px" }}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Box>
      </div>
      {JSON.parse(localStorage.getItem("userInfo")) &&
      JSON.parse(localStorage.getItem("userInfo"))?.isAdmin === 1 ? (
        <CreateNewItemButton title="+Add new Joke" />
      ) : (
        <></>
      )}
      <TableJokes
        jokes={joke?.filter((item) =>
          item?.question.toLowerCase().includes(search.toLowerCase())
        )}
      />
    </div>
  );
};
export default ShowJokes;
