import './index.css';
import React, { useState } from 'react';
import { useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import { Pagination } from '@mui/material';
import * as ingredientServices from "../../services//ingredients";
import swal from 'sweetalert';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';

// import { Pagination } from '@material-ui';

const Table = (props) => {
  const headers = JSON.parse(localStorage.getItem("userInfo")) && JSON.parse(localStorage.getItem("userInfo")).isAdmin === 1 ?
                  ['Check', 'Id', 'Ingredient'] :
                  ['Id', 'Ingredient'];
                  
  const [ingredient, setData] = useState(props.ingredient);

  const [checkedItems, setCheckedItems] = useState(0);
  // const [items, setItems] = useState([]);

  let [countCheckedItems, setCountCheckedItems] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(9);
  
  const [ingredientChecked, setIngredientChecked] = useState([]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = props.ingredient.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    setCountCheckedItems(ingredient.filter(item => item.checked === true).length);
  }, [ingredient]);


  const handleInputChange = (e) => {
    const {value, checked} = e.target
    if(checked){
      setIngredientChecked(pre => [...pre, parseInt(value)])
    }
    else{
      setIngredientChecked(pre => {
        return [...pre.filter(item => item !== parseInt(value))]
      })
    }
    console.log(ingredientChecked)
  }

  const handleDelete = async (id, name) => {
    swal({
      title: "Are you sure?",
      text: `${name} sera supprimé définitivement`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then( async function (isOkay) {
      if (isOkay) {
        ingredientServices.deleteIngredient(id)
          .then(res => {
            if(res.status === 200){
              swal("Ingrédient supprimé!", "Suppression avec succès", "success");
              setTimeout(()=>{window.location.reload(false)},500);
              }
          })
          .catch(err => {
            swal("Nope", "Une erreur s'est produite", "error");
            throw new Error(err)
          })
      }
  });
  };

  const handleDeleteAll = () => {
    swal({
      title: "Are you sure?",
      text: `Ces ingrédients seront supprimés définitivement`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then( async function (isOkay) {
      if (isOkay) {
        ingredientChecked.forEach(ingredients => {
          ingredientServices.deleteIngredient(ingredients)
          .then(res => {
            if(res.status === 200){
              swal(`Ingrédients supprimés!`, `Suppression des ingrédients avec succès!`, "success");
              setTimeout(()=>{window.location.reload(false)},500);
              }
          })
          .catch(err => {
            swal("Nope", "Une erreur s'est produite", "error");
            throw new Error(err)
          })
        })
      }
  });
  };

  
  const handleChange = (event, value) => {
    setCurrentPage(value);
  };
  
  return (
    <>
    {ingredientChecked.length >= 2 ? <div className='buttonContainer m-1'>
            <Button variant="contained" color="error" margin='normal' onClick={handleDeleteAll}>
                Suppression multiple
            </Button>
        </div>:null}
    <div id="tableIngredientsPage">
      <TextField
        id="search-bar"
        className="text"
        onInput={(e) => {
          setCurrentPage(e.target.value);
        }}
        label="page number"
        variant="outlined"
        size="small"
        value={currentPage}
        style={{marginBottom:'10px'}}
      />
      <table className='table1'>
        <thead>
          <tr className='tb1'>
            {headers.map(head => <th>{head}</th>)}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item) => (
            <tr key={item.id}>
              {JSON.parse(localStorage.getItem("userInfo")) && JSON.parse(localStorage.getItem("userInfo")).isAdmin === 1 ?
              <td>
                <input type="checkbox" value={item.id} checked={item.checked} onChange={handleInputChange} />
              </td>:
              <></>}
              <td>{item.id}</td>
              <td>{item.ingredient}</td>
              {JSON.parse(localStorage.getItem("userInfo")) && JSON.parse(localStorage.getItem("userInfo")).isAdmin === 1 ?<td className='btn2'>
                <Link className='btn' to={`edit/${item.id}`}><EditIcon /></Link>
                <button className='btn' onClick={() => handleDelete(item.id, item.ingredient)}><DeleteIcon /></button>
              </td>:
              <></>}
            </tr>
          ))}
        </tbody>
      </table>
      {countCheckedItems > 1 && (
        <button onClick={handleDeleteAll}>Delete All</button>
      )}
      <div className='pagi'>
        <Pagination
          count={Math.ceil(props.ingredient.length / itemsPerPage)}
          page={currentPage}
          variant="outlined"
          shape='rounded'
          onChange={handleChange}
          style={{display:"flex"}}
        />
      </div>
    </div>
    </>
  );
  
};

export default Table;