import React, { useState, useRef } from 'react';

import ReactCrop, {
  centerCrop,
  makeAspectCrop
} from 'react-image-crop';
import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebouneEffect';

import 'react-image-crop/dist/ReactCrop.css';
import TextField from '@mui/material/TextField';
import { Button } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import BackButton from '../backButton/index';
function centerAspectCrop(mediaWidth,mediaHeight,aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

export default function CropImage() {
  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef(null)
  const imgRef = useRef(null)
  const hiddenAnchorRef = useRef(null)
  const blobUrlRef = useRef('')
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState(16 / 9)

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || ''),
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  function onDownloadCropClick() {
    if (!previewCanvasRef.current) {
      throw new Error('Crop canvas does not exist')
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error('Failed to create blob')
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current)
      }
      blobUrlRef.current = URL.createObjectURL(blob)
      hiddenAnchorRef.current.href = blobUrlRef.current
      hiddenAnchorRef.current.click()
    //   setTimeout(() => {
    //     window.location.reload(false)
    //   }, 2000);
    })
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        )
      }
    },
    100,
    [completedCrop, scale, rotate],
  )

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined)
    } else if (imgRef.current) {
      const { width, height } = imgRef.current
      setAspect(16 / 9)
      setCrop(centerAspectCrop(width, height, 16 / 9))
    }
  }

  return (
    <div className="App">
      <div className="column">
        <div className='texte' style={{padding:"10px"}}>
         <BackButton />
        </div>
        <div className='texte' style={{padding:"10px"}}>
            {/* <Button variant="contained" component='label' >
                Upload image
                <input hidden type="file" accept="image/*" onChange={onSelectFile} />
            </Button> */}
            <Button variant="contained" component="label">
                Upload
                <input hidden accept="image/*" multiple type="file" onChange={onSelectFile}/>
            </Button>
            <IconButton color="primary" aria-label="upload picture" component="label">
                <input hidden accept="image/*" type="file" onChange={onSelectFile}/>
                <PhotoCamera />
            </IconButton>
        </div>
        <div className="texte" style={{padding:'10px'}}>
            <TextField 
                id="scale-input" 
                label="Scale:" 
                variant="outlined" 
                type="number"
                InputLabelProps={{
                    shrink: true,
                    step: "0.1"
                }}
                value={scale}
                disabled={!imgSrc}
                onChange={(e) => setScale(e.target.value)}
            />
        </div>
        <div className="texte" style={{padding:'10px'}}>
            <TextField 
                id="rotate-input" 
                label="Rotate:" 
                variant="outlined" 
                type="number"
                InputLabelProps={{
                    shrink: true,
                    step: "0.1"
                }}
                value={rotate}
                disabled={!imgSrc}
                onChange={(e) =>
                    setRotate(Math.min(180, Math.max(-180, e.target.value)))
                }
            />
        </div>
        <div className='texte' style={{padding:'10px'}}>
            <Button variant="contained" component='label' onClick={handleToggleAspectClick}>
                Toggle aspect {aspect ? 'off' : 'on'}
            </Button>
        </div>
      </div>
      <div>
      {!!imgSrc && (
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={aspect}
          style={{margin:'20px'}}
        >
          <img
            ref={imgRef}
            alt="Crop me"
            src={imgSrc}
            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
            onLoad={onImageLoad}
          />
        </ReactCrop>
      )}
      {!!completedCrop && (
        <div>
          <div style={{margin:'20px'}}>
            <canvas
              ref={previewCanvasRef}
              style={{
                border: '1px solid black',
                objectFit: 'contain',
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />
          </div>
          <div className='texte' style={{padding:"10px"}}>
            <Button variant="contained" color="success" component='label' onClick={onDownloadCropClick}>
                Download Crop
            </Button>
                <a
                    ref={hiddenAnchorRef}
                    download
                    style={{
                    position: 'absolute',
                    top: '-200vh',
                    visibility: 'hidden',
                    }}
                >
                    Hidden download
                </a>
          </div>
        </div>
        
      )}
      </div>
          
    </div>
  )
}
