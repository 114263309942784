import "./index.css";
import { useState } from "react";
import Switch from "@mui/material/Switch";
import BackButton from "../../../components/backButton";
import Title from "../../../components/title";
import { Button } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { useEffect } from "react";
import * as userServices from "../../../services/users";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";

const EditUser = () => {
  const title = "Users";
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [confirmed, setConfirmed] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    userServices.getUser(id).then((user) => {
      console.log(user[0].password);
      user[0].blocked === 1 ? setBlocked(true) : setBlocked(false);
      user[0].confirmed === 1 ? setConfirmed(true) : setConfirmed(false);
      user[0].isAdmin === 1 ? setIsAdmin(true) : setIsAdmin(false);
      setUsername(user[0].username);
      setEmail(user[0].email);
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    //on teste si le mdp==mdp confirmé
    // var password = document.getElementById("passwordInput").value;
    // var confirm_password = document.getElementById("confirmPasswordInput").value;

    if (!username || !email) {
      swal("Nope", "Veuillez remplir toutes les cases!", "error");
    } else {
      userServices
        .updateUser(username, email, isAdmin, confirmed, blocked, id)
        .then((res) => {
          if (res.data.status === 200) {
            swal(
              "Utilisateur mis à jour!",
              "Mise à jour avec succès",
              "success"
            );
            navigate("/Users");
          }
        })
        .catch((err) => {
          swal("Nope", "Une erreur s'est produite", "error");
          throw new Error(err);
        });
    }
  };

  return (
    <div>
      <div className="back">
        <BackButton />
      </div>
      <div className="width">
        <Title title={title} />
        <form onSubmit={handleSubmit} className=" forme shadow p-1 ">
          <div className="row">
            <div className="texte">
              <TextField
                type="text"
                placeholder="Username"
                variant="outlined"
                margin="normal"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="texte">
              <TextField
                type="email"
                placeholder="E-mail"
                variant="outlined"
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="mt-2 ">
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={confirmed && confirmed}
                      onChange={(e) => setConfirmed(e.target.checked)}
                    />
                  }
                  label="Confirmed"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={blocked && blocked}
                      onChange={(e) => setBlocked(e.target.checked)}
                    />
                  }
                  label="Blocked"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={isAdmin && isAdmin}
                      onChange={(e) => setIsAdmin(e.target.checked)}
                    />
                  }
                  label="Admin"
                />
              </FormGroup>
            </FormControl>
          </div>
          <div className="mt-2">
            <Button
              variant="contained"
              color="success"
              type="submit"
              margin="normal"
            >
              Valider
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default EditUser;
