import axios from "axios";
import queryString from "query-string";
import { baseUrl } from "../config/config" ;

if (sessionStorage.getItem("jwt"))axios.defaults.headers.common = {'Authorization': `Bearer ${sessionStorage.getItem("jwt").split('"')[1]}`}
const baseURL = baseUrl

const axiosClient = axios.create({
  baseURL,
  paramsSerializer: {
    encode: params => queryString.stringify(params)
  }
});
// console.log(typeof(sessionStorage.getItem("jwt").split('"')[1]))
axiosClient.interceptors.request.use(async config => {
  return {
    ...config,
    headers: {
      "Content-Type": "multipart/form-data",
      "Authorization": `Bearer ${sessionStorage.getItem("jwt").split('"')[1]}`
    }
  };
});

//à modifier en fonction de la valeur retourner par le back
axiosClient.interceptors.response.use((response) => {
  if (response && response.data) return response;
  return response;
}, (err) => {
  throw err.response.data;
});

export default axiosClient;