import React from 'react';
import { useEffect, useState } from "react";
import * as jokesServices from "../../../services/jokes";

function TotalJokes() {
    const [nbrJoke, setNbrJoke] = useState();

    useEffect(()=>{
        jokesServices.getAllJokes()
        .then(jokes => {
            setNbrJoke(jokes.length)
        })
        console.log(nbrJoke)
    }, [nbrJoke])
    
    return (
        <div className=" circle text-white text-center p-2">
        <div className="my-5">
            <h2>Blagues</h2>
            <h1 className='display-5'>{nbrJoke}</h1>
        </div>
        </div>
    )
}

export default TotalJokes;