import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";

function BackButton() {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  return (
    <div>
      <IconButton
        color="success"
        aria-label="back button"
        component="label"
        onClick={goBack}
      >
        <ArrowCircleLeftOutlinedIcon fontSize="large" />
      </IconButton>
    </div>
  );
}

export default BackButton;
