import "./index.css";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as ingredientsService from "../../../services/ingredients";
import * as recipeHasIngredient from "../../../services/recipeHasIngredient";
import BackButton from "../../../components/backButton";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Title from "../../../components/title";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import InfoIcon from "@mui/icons-material/Info";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import CircularProgress from "@mui/material/CircularProgress";
import { IoFastFoodOutline } from "react-icons/io5";
import { FaIceCream } from "react-icons/fa";
import { GiCookingPot } from "react-icons/gi";
import { IoIosRemoveCircle } from "react-icons/io";
import { IoIosAddCircle } from "react-icons/io";
import swal from "sweetalert";
const EditIngredient = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [ingredientName, setIngredient] = useState("");
  const [title, setTitle] = useState("Edit Ingredient");
  const [recipe, setRecipe] = useState();
  const [loading, setLoading] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [recipesDontContainTheIngredient, setRecipesDontContainTheIngredient] =
    useState();
  const [filteredData, setFilteredData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [inputValues, setInputValues] = useState("");
  useEffect(() => {
    setLoading(true);
    ingredientsService.getIngredient(id).then((ingredient) => {
      setIngredient(ingredient[0].ingredient);
      setTitle(ingredient[0].ingredient);
    });
    recipeHasIngredient
      .getRecipeByIngredient(id)
      .then((recipe) => {
        recipe.data && setRecipe(recipe.data);
        setLoading(false);
      })
      .catch((err) => console.error(err));
  }, [id]);

  const filtrerDonnees = () => {
    setLoadings(true);
    const filtres = recipesDontContainTheIngredient?.filter((donnee) =>
      donnee.recipe_name.toLowerCase().includes(inputValue.toLowerCase())
    );
    setTimeout(() => {
      setFilteredData(filtres);
      setLoadings(false);
    }, 3000);
  };

  useEffect(() => {
    recipeHasIngredient.getAllRecipeAndHisIngredients().then((res) => {
      if (res)
        setRecipesDontContainTheIngredient(
          res?.filter(
            (el) =>
              !el.ingredients.some((el) => el.ingredient === ingredientName)
          )
        );
    });
  }, [ingredientName]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    await ingredientsService.updateIngredient(id, ingredientName);
    navigate("/Ingredients");
  };

  const recipeType = (recipe_type) => {
    if (recipe_type === "Apéro/Entrée") {
      return <IoFastFoodOutline />;
    } else if (recipe_type === "Plat") {
      return <GiCookingPot />;
    } else {
      return <FaIceCream />;
    }
  };

  const handleRemoveIngredient = (
    ingredient_name,
    recipe_name,
    ingredient_id,
    recipe_id
  ) => {
    swal({
      title: "Are you sure?",
      text: `${ingredient_name} sera retiré de ${recipe_name} définitivement`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async function (isOkay) {
      if (isOkay) {
        recipeHasIngredient
          .deteleRecipeIngredientOfARecipe(ingredient_id, recipe_id)
          .then((res) => {
            if (res.status === 200) {
              swal(
                "Ingrédient supprimé de la recette!",
                "Suppression avec succès",
                "success"
              );
              const apartTheRemovedIngredient = recipe?.filter(
                (el) => el.id !== recipe_id
              );
              setRecipe(apartTheRemovedIngredient);
              window.location.reload(false);
            }
          })
          .catch((err) => {
            swal("Nope", "Une erreur s'est produite", "error");
            throw new Error(err);
          });
      }
    });
  };

  const handleAddIngredientToRecipe = (
    ingredient_name,
    recipe_name,
    ingredient_id,
    recipe_id
  ) => {
    swal({
      title: "Are you sure?",
      text: `${ingredient_name} sera ajouté de ${recipe_name}`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async function (isOkay) {
      if (isOkay) {
        recipeHasIngredient
          .addRecipeIdAndIngredientId(recipe_id, ingredient_id)
          .then((res) => {
            if (res.status === 200) {
              swal(
                "Ingrédient ajouté à la recette!",
                "Ajout avec succès",
                "success"
              );
              const apartTheRemovedIngredient = recipe?.filter(
                (el) => el.id !== recipe_id
              );
              setRecipe(apartTheRemovedIngredient);
              window.location.reload(false);
            }
          })
          .catch((err) => {
            swal("Nope", "Une erreur s'est produite", "error");
            throw new Error(err);
          });
      }
    });
  };

  return (
    <>
      <div className="container">
        <div className="div1">
          <BackButton />
        </div>
        <div className="div2">
          <Title title={title} />
          <form className=" form shadow" onSubmit={handleSubmit}>
            <TextField
              label="Ingredient"
              variant="outlined"
              margin="normal"
              value={ingredientName}
              onChange={(e) => setIngredient(e.target.value)}
            />

            <Button
              variant="contained"
              color="success"
              type="submit"
              margin="normal"
            >
              Valider
            </Button>
          </form>
        </div>
      </div>

      <Box style={{ padding: "0px 80px" }}>
        <Grid item xs={12} md={6}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            Recipe linked with this ingredient
          </Typography>
          {loading && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          )}
          <List>
            <TextField
              id="search-bar"
              className="text"
              onInput={(e) => {
                setInputValues(e.target.value);
              }}
              label="recip name"
              variant="outlined"
              size="small"
            />
            {recipe &&
              recipe
                ?.filter((item) =>
                  item?.recipe_name
                    .toLowerCase()
                    .includes(inputValues.toLowerCase())
                )
                .map((recipe) => (
                  <ListItem
                    secondaryAction={
                      <div key={recipe?.id}>
                        <IconButton edge="end" aria-label="go to">
                          <Link to={`/Recipes/edit/${recipe?.id}`}>
                            <InfoIcon />
                          </Link>
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          style={{ paddingBottom: "13px" }}
                        >
                          <IoIosRemoveCircle
                            onClick={() =>
                              handleRemoveIngredient(
                                ingredientName,
                                recipe?.recipe_name,
                                id,
                                recipe.id
                              )
                            }
                          />
                        </IconButton>
                      </div>
                    }
                  >
                    <ListItemAvatar>
                      <Avatar>{recipeType(recipe?.recipe_type)}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={recipe?.recipe_name}
                      secondary={recipe?.recipe_type}
                    />
                  </ListItem>
                ))}
          </List>
        </Grid>
      </Box>

      <Box style={{ padding: "0px 80px" }}>
        <Grid item xs={12} md={6}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            Recipe not linked with this ingredient
          </Typography>
          <List>
            <TextField
              id="search-bar"
              className="text"
              onInput={(e) => {
                setInputValue(e.target.value);
              }}
              label="recip name"
              variant="outlined"
              size="small"
            />
            <Button
              variant="contained"
              type="submit"
              margin="normal"
              style={{ margin: "1px 0 0 2px" }}
              onClick={filtrerDonnees}
            >
              Search
            </Button>

            {loadings && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            )}

            {filteredData?.map((recipe) => (
              <ListItem
                secondaryAction={
                  <div key={recipe.id}>
                    <IconButton edge="end" aria-label="go to">
                      <Link to={`/Recipes/edit/${recipe.id}`}>
                        <InfoIcon />
                      </Link>
                    </IconButton>
                    <IconButton
                      aria-label="add"
                      style={{ paddingBottom: "13px" }}
                    >
                      <IoIosAddCircle
                        onClick={() =>
                          handleAddIngredientToRecipe(
                            ingredientName,
                            recipe.recipe_name,
                            id,
                            recipe.id
                          )
                        }
                      />
                    </IconButton>
                  </div>
                }
              >
                <ListItemAvatar>
                  <Avatar>{recipeType(recipe.recipe_type)}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={recipe.recipe_name}
                  secondary={recipe.recipe_type}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Box>
    </>
  );
};

export default EditIngredient;
