import React, { useState, useEffect } from "react";
import "./index.css";
import CreateNewItemButton from "../../../components/createButton";
import Table from "../../../components/table/ingredient";
import Title from "../../../components/title";
import * as ingredientsService from "../../../services/ingredients";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

function ShowIngredients() {
  const title = "Ingredients";
  const [ingredients, setIgredients] = useState([]);
  const [search, setSearch] = useState("");
  useEffect(() => {
    ingredientsService
      .getAllIngredients()
      .then((ingredients) => setIgredients(ingredients));
  }, []);
  return (
    <div>
      <Title title={title} />
      <div>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <TextField
            id="input-with-sx"
            label="Search"
            variant="standard"
            style={{ marginBottom: "10px" }}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Box>
      </div>
      {JSON.parse(localStorage.getItem("userInfo")) &&
      JSON.parse(localStorage.getItem("userInfo")).isAdmin === 1 ? (
        <CreateNewItemButton title="+ Add new Ingredient" />
      ) : (
        <></>
      )}
      <Table
        ingredient={ingredients.filter((item) =>
          item.ingredient.toLowerCase().includes(search.toLowerCase())
        )}
      />
    </div>
  );
}
export default ShowIngredients;
