import React from 'react';

function Title({ title }) {
    return (
        <div className='titleContainer'>
            <h1 className='title'> {title}</h1>
        </div>
    );
};

export default Title;
