import "./index.css";
import { useState } from "react";
import BackButton from "../../../components/backButton";
import { useNavigate } from "react-router-dom";
import Title from "../../../components/title";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import * as jokeServices from "../../../services/jokes";
import swal from "sweetalert";

export const CreateJoke = () => {
  const title = "Create new Joke";
  const navigate = useNavigate();

  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!question || !answer) {
      swal("Nope", "Veuillez remplir toutes les cases!", "error");
    } else {
      jokeServices
        .createJoke(question, answer)
        .then((res) => {
          if (res.status === 200) {
            swal("Joke crée!", "Création de joke avec succès", "success");
            setTimeout(() => {
              navigate("/Jokes");
            }, 2000);
          }
        })
        .catch((err) => {
          swal("Nope", "Une erreur s'est produite", "error");
          throw new Error(err);
        });
    }
  };

  return (
    <div>
      <div className="back">
        <BackButton />
      </div>
      <div className="width">
        <Title title={title} />
        <form
          onSubmit={handleSubmit}
          className=" forme shadow p-1 "
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="column">
            <div className="texte">
              <TextField
                sx={{
                  width: 600,
                }}
                type="text"
                label="Question"
                variant="outlined"
                margin="normal"
                multiline
                maxRows={1000}
                onChange={(e) => setQuestion(e.target.value)}
              />
            </div>
            <div className="texte">
              <TextField
                sx={{
                  width: 600,
                }}
                type="text"
                label="Answer"
                variant="outlined"
                margin="normal"
                multiline
                maxRows={1000}
                onChange={(e) => setAnswer(e.target.value)}
              />
            </div>
          </div>
          <div className="mt-2">
            <Button
              variant="contained"
              color="success"
              type="submit"
              margin="normal"
            >
              Valider
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default CreateJoke;
