import * as config from "../config/config.js";
import axiosClient from "../api/axio.client.js";

// ingredientsService.getAllIngredients().then(x => { x.map(y => console.log(y)) });
export const getAllIngredients = async () => {
  try {
    const response = await axiosClient.get(
      `${config.baseUrl}/ingredients/get-all`
    );
    return response.data;
  } catch (error) {
    return [];
  }
};

// ingredientsService.getIngredient(4).then(x => console.log(x[0]));
export const getIngredient = async (id) => {
  try {
    const response = await axiosClient.get(
      `${config.baseUrl}/ingredients/id/${id}`
    );
    return response.data.data;
  } catch (error) {
    return error;
  }
};

// ingredientsService.createIngredient(ingredientName);
export const createIngredient = async (ingredient) => {
  try {
    const response = await axiosClient.post(
      `${config.baseUrl}/ingredients`,
      { ingredient: ingredient },
      {
        headers: {
          "Cache-Control": "no-cache",
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    return [];
  }
};

//ingredientsService.updateIngredient(id,ingredientName);
export const updateIngredient = async (id, ingredient) => {
  try {
    const response = await axiosClient.put(
      `${config.baseUrl}/ingredients`,
      { id: id, ingredient: ingredient },
      {
        headers: {
          "Cache-Control": "no-cache",
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    return [];
  }
};

// ingredientsService.deleteIngredient(id);
export const deleteIngredient = async (id) => {
  try {
    const response = await axiosClient.delete(
      `${config.baseUrl}/ingredients/${id}`
    );
    return response;
  } catch (error) {
    return [];
  }
};
