import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import * as recipeServices from "../../../services/recipes";
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend} from 'chart.js';

ChartJS.register( CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

const StatType = () => {
  const [nbrEntree, setNbrEntree] = useState([]);
  const [nbrPlat, setNbrPlat] = useState([]);
  const [nbrDessert, setNbrDessert] = useState([]);

  useEffect(()=>{
    recipeServices.getAllRecipes()
    .then(recipes => {
      const entree = recipes.filter(recipe => recipe.recipe_type === 'Apéro/Entrée');
      const plat = recipes.filter(recipe => recipe.recipe_type === 'Plat');
      const dessert = recipes.filter(recipe => recipe.recipe_type === 'Dessert/Goûter');
      setNbrEntree(entree);
      setNbrPlat(plat);
      setNbrDessert(dessert);
    })
  }, [])

  const data = {
    labels: ['Entrée', 'Plat', 'Dessert'],
    datasets: [
      {
        label: "Les types de recette",
        data: [nbrEntree && nbrEntree.length,  nbrPlat && nbrPlat.length, nbrDessert && nbrDessert.length],
        backgroundColor: [
          "#983400",
          "#e73119",
          "#F39C12",
        ],
      },
    ],
  };

  return (
    <div>
        <Bar data={data} />
    </div>
    );
}

export default StatType;