import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";

import AuthPage from "../pages/Auth";

import HomePage from "../pages/Home";
import UsersPage from "../pages/Users";
import IngredientsPage from "../pages/Ingredients";
import RecipesPage from "../pages/Recipes";
import ShowRecipes from "../pages/Recipes/showRecipe";
import EditRecipe from "../pages/Recipes/editRecipe";
import EditIngredient from "../pages/Ingredients/editIngredient";
import ShowIngredients from "../pages/Ingredients/showIngredients";
import CreateIngredient from "../pages/Ingredients/createIngredient";
import ConnexionPage from "../pages/Auth/connexion";
import EditUser from "../pages/Users/editUser";
import ShowUsers from "../pages/Users/showUsers";
import NotFound from "../pages/NotFound";
import Createrecipe from "../pages/Recipes/createrecipe";
import CreateUser from "../pages/Users/createUser";
import Profil from "../pages/Profil/Profil";

import ShowJokes from "../pages/Jokes/showJoke";
import JokesPage from "../pages/Jokes";
import CreateJoke from "../pages/Jokes/createJoke";
import EditJoke from "../pages/Jokes/editJoke";

import Unauthorized from "../pages/unauthorized/unauthorized";
import CropImage from "../components/cropPicture/cropPicture";
const RouterConfig = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return (
    <Routes>
      <Route exact path="/" element={<HomePage />} />

      <Route exact path="/profil" element={<Profil />} />

      <Route path="/Users" element={<UsersPage />}>
        <Route path="" element={<ShowUsers />} />
        <Route path="create" element={<CreateUser />} />
        <Route path="edit/:id" element={<EditUser />} />
      </Route>
      <Route path="/Ingredients" element={<IngredientsPage />}>
        <Route path="" element={<ShowIngredients />} />
        <Route path="create" element={<CreateIngredient />} />
        <Route path="edit/:id" element={<EditIngredient />} />
      </Route>
      <Route path="/Recipes" element={<RecipesPage />}>
        <Route path="" element={<ShowRecipes />} />
        <Route path="create" element={<Createrecipe />} />
        <Route path="edit/:id" element={<EditRecipe />} />
      </Route>
      <Route path="/Jokes" element={<JokesPage />}>
        <Route path="" element={<ShowJokes />} />
        <Route path="create" element={<CreateJoke />} />
        <Route path="edit/:id" element={<EditJoke />} />
      </Route>

      <Route path="*" element={<NotFound />} />

      {/* Authentification */}
      <Route path="/auth" element={<AuthPage />}>
        <Route
          path=""
          element={<ConnexionPage authfunc={setIsAuthenticated} />}
        />
      </Route>

      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="/crop" element={<CropImage />} />
    </Routes>
  );
};

export default RouterConfig;
