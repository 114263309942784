import React, { useState, useEffect } from "react";
import "./index.css";
import BackButton from "../../../components/backButton";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import { Button } from "@mui/material";
// import { Ingredient_select } from "../../../components/Select/ingredient";
// import { Recipe_type_select } from "../../../components/Select/recipes_type";
import { useNavigate, useParams } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Title from "../../../components/title";
import DeleteIcon from "@mui/icons-material/Delete";
import * as recipeServices from "../../../services/recipes";
// import { useLocation } from "react-router-dom";
// import Image from "../../../components/logo";
import Select from "react-select";
import swal from "sweetalert";
import {
  getAllIngredients,
  getIngredient,
} from "../../../services/ingredients";
import * as recipeHasIngredientService from "../../../services/recipeHasIngredient";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import * as config from "../../../config/config.js";

import {
  getIngredientByRecipe,
  deteleRecipeIngredientOfARecipe,
} from "../../../services/recipeHasIngredient";
import * as recipeIdAndIngredientIdService from "../../../services/recipeHasIngredient";
import { Link } from "react-router-dom";
const EditRecipe = () => {
  // const location = useLocation(); //nouveau

  // const navigate = useNavigate()
  // const { item = {}, isEditMode } = location.state || {};
  const [title, setTitle] = useState("Recipe");
  const [recipe_name, setRecipe_name] = useState("");
  const [nb_personnes, setNb_personnes] = useState("");
  const [Author, setAuthor] = useState(null);
  const [ingredients_quantities, setIngredients_quantities] = useState("");
  const [steps, setSteps] = useState("");
  const [preparationTime, setpreparationTime] = useState(null);
  const [link, setLink] = useState("");
  const [cooking_time, setCooking_time] = useState(null);
  const [standing_time, setStanding_time] = useState(null);
  const [recipe_type, setRecipe_type] = useState("");
  const [four, setFour] = useState(null);
  const [vegan, setVegan] = useState(null);
  const [gluten, setGluten] = useState(null);
  const [lactose, setLactose] = useState(null);
  const [url_media, setMedia] = useState();

  const [data, setData] = useState("");
  const [Ingredients, setIngredients] = useState([]);

  const { id } = useParams();
  const navigate = useNavigate();

  const [ingredientRecipe, setIngredientRecipe] = useState();

  useEffect(() => {
    recipeServices.getRecipe(id).then((recipe) => {
      setTitle(recipe.recipe_name);
      setRecipe_name(recipe.recipe_name);
      setNb_personnes(recipe.nb_personnes);
      setSteps(recipe.Steps);
      setpreparationTime(recipe.preparation_time);
      setCooking_time(recipe.cooking_time);
      setStanding_time(recipe.standing_time);
      setAuthor(recipe.author);
      setLink(recipe.link);
      recipe.vegan === 1 ? setVegan(true) : setVegan(false);
      recipe.gluten === 1 ? setGluten(true) : setGluten(false);
      recipe.four === 1 ? setFour(true) : setFour(false);
      recipe.lactose === 1 ? setLactose(true) : setLactose(false);
      setRecipe_type(recipe.Recipe_type);
      setMedia(recipe.media.url);
      setIngredients_quantities(recipe.ingredients_quantities);
    });
    // console.log({gluten, lactose, four, vegan})
  }, []);

  useEffect(() => {
    getAllIngredients().then((ingredients) => {
      ingredients?.map(
        (ingredient) => (
          // eslint-disable-next-line no-sequences
          Object.assign(ingredient, { value: ingredient.id })["ingredient"],
          // eslint-disable-next-line no-sequences
          // delete ingredient['ingredient'],
          (ingredient["label"] = ingredient.ingredient)
        )
      );
      setData(ingredients);
    });
  }, []);

  useEffect(() => {
    getIngredientByRecipe(id).then((ingredients) => {
      setIngredientRecipe(ingredients.data);
    });
  }, []);

  var recipeType = [
    {
      value: "Apéro/Entrée",
      label: "Apéro/Entrée",
    },
    {
      value: "Plat",
      label: "Plat",
    },
    {
      value: "Dessert/Goûter",
      label: "Dessert/Goûter",
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("recipe_name", recipe_name);

    formData.append("nb_personnes", nb_personnes);

    formData.append("Author", Author);

    formData.append("steps", steps);

    formData.append("preparation_time", preparationTime);

    formData.append("standing_time", standing_time);

    formData.append("vegan", Number(vegan));

    formData.append("cooking_time", cooking_time);

    formData.append("recipe_type", recipe_type.toString());

    formData.append("four", Number(four));

    formData.append("link", link);

    formData.append("gluten", Number(gluten));

    formData.append("lactose", Number(lactose));

    formData.append("url_media", url_media);

    formData.append("Ingredients_quantities", ingredients_quantities);

    formData.append("id", id);

    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    recipeServices
      .updateOneRecipe(formData)
      .then((res) => {
        //console.log(res)
        if (res.serverStatus === 2) {
          Ingredients?.forEach((ingredientId) => {
            console.log(Ingredients);
            recipeIdAndIngredientIdService
              .addRecipeIdAndIngredientId(id, ingredientId)
              .then((res) => {
                if (res.status === 200) {
                  swal(
                    "Recette mis à jour!",
                    "Mis à jour de la recette avec succès",
                    "success"
                  );
                  setTimeout(() => {
                    navigate("/Recipes");
                  }, 2000);
                }
              })
              .catch((err) => {
                swal("Nope", "Une erreur s'est produite", "error");
                throw new Error(err);
              });
          });
        }
      })
      .catch((err) => {
        swal("Nope", "Une erreur s'est produite", "error");
        throw new Error(err);
      });
  };

  const handleChange = (e) => {
    setRecipe_type(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const handleChangeIngredient = (e) => {
    setIngredients(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    setMedia(null);
  };
  const deleteIngredient = (ingredient_name, ingredientId, recipeId) => {
    swal({
      title: "Are you sure?",
      text: `${ingredient_name} sera supprimé définitivement`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async function (isOkay) {
      if (isOkay) {
        await deteleRecipeIngredientOfARecipe(ingredientId, recipeId)
          .then((res) => {
            // console.log(res);
            if (res.status === 200) {
              const newIngredient = ingredientRecipe?.filter(
                (ingredient) => ingredient?.id !== ingredientId
              );
              setIngredientRecipe(newIngredient);
            }
          })
          .catch((error) => console.error(error));
      }
    });
  };
  return (
    <div id="body">
      <div>
        <div className="back">
          <BackButton />
        </div>
        <div className="width">
          <Title title={title} />
          <form onSubmit={handleSubmit} className=" forme shadow p-1 ">
            <div className="">
              <div className="row">
                <div className="photo texte p-1">
                  <div className="uploader">
                    <div className="upload">
                      <Button
                        variant="contained"
                        color="error"
                        component="label"
                      >
                        <Link
                          to={"/crop"}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          crop image
                        </Link>
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="texte">
                  <TextField
                    className="w-100"
                    label="Recipe Name"
                    variant="outlined"
                    margin="normal"
                    value={recipe_name}
                    multiline
                    maxRows={1000}
                    onChange={(e) => setRecipe_name(e.target.value)}
                  />
                </div>
                <div className="texte">
                  <TextField
                    className="w-100"
                    placeholder="Author"
                    variant="outlined"
                    margin="normal"
                    value={Author}
                    multiline
                    maxRows={1000}
                    onChange={(e) => setAuthor(e.target.value)}
                  />
                </div>
                <div className="texte">
                  <TextField
                    className="w-100"
                    type="number"
                    label="Nbpersonnes"
                    variant="outlined"
                    margin="normal"
                    value={nb_personnes}
                    onChange={(e) => setNb_personnes(e.target.value)}
                  />
                </div>
                <div className="texte">
                  <TextField
                    className="w-100"
                    type="url"
                    label=" Link"
                    variant="outlined"
                    margin="normal"
                    value={link}
                    multiline
                    maxRows={1000}
                    onChange={(e) => setLink(e.target.value)}
                  />
                </div>
              </div>
              <div className="row ">
                <div className="texte">
                  <TextField
                    className="w-100"
                    type="time in minute"
                    placeholder="Preparation Time"
                    variant="outlined"
                    margin="normal"
                    value={preparationTime}
                    multiline
                    maxRows={1000}
                    onChange={(e) => setpreparationTime(e.target.value)}
                  />
                </div>
                <div className="texte">
                  <TextField
                    className="w-100"
                    type="time in minute"
                    placeholder="Cooking Time"
                    variant="outlined"
                    margin="normal"
                    value={cooking_time}
                    multiline
                    maxRows={1000}
                    onChange={(e) => setCooking_time(e.target.value)}
                  />
                </div>
                <div className="texte">
                  <TextField
                    className="w-100"
                    type="time in minute"
                    placeholder="Standing Time"
                    variant="outlined"
                    margin="normal"
                    value={standing_time}
                    multiline
                    maxRows={1000}
                    onChange={(e) => setStanding_time(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="texte">
                  <TextField
                    className="w-100"
                    type="text"
                    label="Steps"
                    variant="outlined"
                    margin="normal"
                    value={steps}
                    multiline
                    maxRows={1000}
                    onChange={(e) => setSteps(e.target.value)}
                  />
                </div>
                <div className="texte">
                  <TextField
                    className="w-100"
                    type="text"
                    label=" Ingredient Quantity"
                    variant="outlined"
                    margin="normal"
                    value={ingredients_quantities}
                    multiline
                    maxRows={1000}
                    onChange={(e) => setIngredients_quantities(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div
              className="texte"
              style={{
                border: "1px solid lightgray",
                borderRadius: "5px",
                marginTop: "10px",
                width: "98%",
              }}
            >
              <Typography
                sx={{ mt: 2, ml: 2, mb: 2 }}
                variant="h6"
                component="div"
              >
                Liste des ingrédients
              </Typography>
              <List sx={{ p: 1 }}>
                {ingredientRecipe &&
                  ingredientRecipe?.map((ingredient) => (
                    <ListItem
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() =>
                            deleteIngredient(
                              ingredient.ingredient,
                              ingredient.id,
                              id
                            )
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText primary={ingredient?.ingredient} />
                    </ListItem>
                  ))}
              </List>
            </div>
            <div className="row ">
              <div className="selectitem">
                <div className="texte">
                  {recipe_type && (
                    <div style={{ marginTop: 20, lineHeight: "25px" }}>
                      <div>
                        <b>Type selectionné: </b>{" "}
                        {JSON.stringify(recipe_type, null, 2)}
                      </div>
                    </div>
                  )}
                  <Select
                    className="dropdown"
                    placeholder="Select Option"
                    value={recipeType?.filter((obj) =>
                      recipe_type.includes(obj.value)
                    )} // set selected values
                    options={recipeType} // set list of the data
                    onChange={handleChange} // assign onChange function
                    isMulti
                    isClearable
                  />
                </div>
                {data && (
                  <div className="texte">
                    {Ingredients && (
                      <div style={{ marginTop: 20, lineHeight: "25px" }}>
                        <div>
                          {Ingredients?.length !== 1 ? (
                            <b>Ingrédients selectionnés: </b>
                          ) : (
                            <b>Ingrédient selectionné: </b>
                          )}{" "}
                          {JSON.stringify(Ingredients, null, 2)}
                        </div>
                      </div>
                    )}
                    <Select
                      className="dropdown"
                      placeholder="Select Option"
                      value={data?.filter((obj) =>
                        Ingredients?.includes(obj.value)
                      )} // set selected values
                      options={data?.filter((el) => {
                        return !ingredientRecipe?.some((ingredient) => {
                          return el?.ingredient === ingredient?.ingredient;
                        });
                      })} // set list of the data
                      onChange={handleChangeIngredient} // assign onChange function
                      isMulti
                      isClearable
                    />
                  </div>
                )}
              </div>
              <div className="photo shadow p-1">
                {url_media && (
                  <img
                    className="imagephoto"
                    src={
                      typeof url_media === "string"
                        ? `${config.baseUrl}${url_media}`
                        : URL.createObjectURL(url_media)
                    }
                    alt="Uploaded Image"
                  />
                )}
                <div className="uploader">
                  <div className="upload">
                    <Button variant="contained" component="label">
                      Upload
                      <input
                        hidden
                        type="file"
                        name="url_media"
                        accept="image/*"
                        multiple
                        onChange={(e) => setMedia(e.target.files[0])}
                      />
                    </Button>
                  </div>
                  <div className="m-5">
                    <button onClick={handleDelete} className="btn12">
                      <DeleteIcon />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={four && four}
                        onChange={(e) => setFour(e.target.checked)}
                      />
                    }
                    label="Four"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={vegan && vegan}
                        onChange={(e) => setVegan(e.target.checked)}
                      />
                    }
                    label="Vegan"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={gluten && gluten}
                        onChange={(e) => setGluten(e.target.checked)}
                      />
                    }
                    label="Gluten"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={lactose && lactose}
                        onChange={(e) => setLactose(e.target.checked)}
                      />
                    }
                    label="Lactose"
                  />
                </FormGroup>
              </FormControl>
            </div>

            <Button
              variant="contained"
              color="success"
              type="submit"
              margin="normal"
            >
              Valider
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};
export default EditRecipe;
