import React, {useState, useEffect} from 'react';
import * as recipeServices from "../../../services/recipes";
import Table from 'react-bootstrap/Table';
import Typography from '@mui/material/Typography';
import '../../../pages/Home/index.css';

function StatMultifiltre() {
    const [recipeWithFourWithVeganWithLactoseWithGluten, setRecipeWithFourWithVeganWithLactoseWithGluten] = useState([]);
    const [recipeWithFourWithVeganWithLactoseNoGluten, setRecipeWithFourWithVeganWithLactoseNoGluten] = useState([]);
    const [recipeWithFourWithVeganNoLactoseNoGluten, setRecipeWithFourWithVeganNoLactoseNoGluten] = useState([]);
    const [recipeWithFourNoVeganNoLactoseNoGluten, setRecipeWithFourNoVeganNoLactoseNoGluten] = useState([]);

    const [recipeNoFourWithVeganWithLactoseWithGluten, setRecipeNoFourWithVeganWithLactoseWithGluten] = useState([]);
    const [recipeNoFourNoVeganWithLactoseWithGluten, setRecipeNoFourNoVeganWithLactoseWithGluten] = useState([]);
    const [recipeNoFourNoVeganNoLactoseWithGluten, setRecipeNoFourNoVeganNoLactoseWithGluten] = useState([]);
    const [recipeNoFourNoVeganNoLactoseNoGluten, setRecipeNoFourNoVeganNoLactoseNoGluten] = useState([]);

    const [recipeWithFourWithVeganNoLactoseWithGluten, setRecipeWithFourWithVeganNoLactoseWithGluten] = useState([]);
    const [recipeWithFourNoVeganNoLactoseWithGluten, setRecipeWithFourNoVeganNoLactoseWithGluten] = useState([]);
    const [recipeWithFourNoVeganWithLactoseWithGluten, setRecipeWithFourNoVeganWithLactoseWithGluten] = useState([]);
    const [recipeNoFourWithVeganWithLactoseNoGluten, setRecipeNoFourWithVeganWithLactoseNoGluten] = useState([]);

    const [recipeWithFourNoVeganWithLactoseNoGluten, setRecipeWithFourNoVeganWithLactoseNoGluten] = useState([]);
    const [recipeNoFourWithVeganNoLactoseWithGluten, setRecipeNoFourWithVeganNoLactoseWithGluten] = useState([]);
    const [recipeNoFourWithVeganNoLactoseNoGluten, setRecipeNoFourWithVeganNoLactoseNoGluten] = useState([]);
    const [recipeNoFourNoVeganWithLactoseNoGluten, setRecipeNoFourNoVeganWithLactoseNoGluten] = useState([]);



    useEffect(()=>{
        recipeServices.getAllRecipes()
        .then(recipes => {
          const recipeWithFourWithVeganWithLactoseWithGluten = recipes.filter(recipe => recipe.four === 1)
                                                                      .filter(recipe => recipe.vegan === 1)
                                                                      .filter(recipe => recipe.lactose === 1)
                                                                      .filter(recipe => recipe.gluten === 1)
          setRecipeWithFourWithVeganWithLactoseWithGluten(recipeWithFourWithVeganWithLactoseWithGluten); 

          const recipeWithFourWithVeganWithLactoseNoGluten = recipes.filter(recipe => recipe.four === 1)
                                                                .filter(recipe => recipe.vegan === 1)
                                                                .filter(recipe => recipe.lactose === 1)
                                                                .filter(recipe => recipe.gluten === 0)
          setRecipeWithFourWithVeganWithLactoseNoGluten(recipeWithFourWithVeganWithLactoseNoGluten);

          const recipeWithFourWithVeganNoLactoseNoGluten = recipes.filter(recipe => recipe.four === 1)
                                                                .filter(recipe => recipe.vegan === 1)
                                                                .filter(recipe => recipe.lactose === 0)
                                                                .filter(recipe => recipe.gluten === 0)
          setRecipeWithFourWithVeganNoLactoseNoGluten(recipeWithFourWithVeganNoLactoseNoGluten);  
          
          const recipeWithFourNoVeganNoLactoseNoGluten = recipes.filter(recipe => recipe.four === 1)
                                                                      .filter(recipe => recipe.vegan === 0)
                                                                      .filter(recipe => recipe.lactose === 0)
                                                                      .filter(recipe => recipe.gluten === 0)
          setRecipeWithFourNoVeganNoLactoseNoGluten(recipeWithFourNoVeganNoLactoseNoGluten);

          const recipeNoFourWithVeganWithLactoseWithGluten = recipes.filter(recipe => recipe.four === 0)
                                                                      .filter(recipe => recipe.vegan === 1)
                                                                      .filter(recipe => recipe.lactose === 1)
                                                                      .filter(recipe => recipe.gluten === 1)
          setRecipeNoFourWithVeganWithLactoseWithGluten(recipeNoFourWithVeganWithLactoseWithGluten);

          const recipeNoFourNoVeganWithLactoseWithGluten = recipes.filter(recipe => recipe.four === 0)
                                                                      .filter(recipe => recipe.vegan === 0)
                                                                      .filter(recipe => recipe.lactose === 1)
                                                                      .filter(recipe => recipe.gluten === 1)
          setRecipeNoFourNoVeganWithLactoseWithGluten(recipeNoFourNoVeganWithLactoseWithGluten);

          const recipeNoFourNoVeganNoLactoseWithGluten = recipes.filter(recipe => recipe.four === 0)
                                                                      .filter(recipe => recipe.vegan === 0)
                                                                      .filter(recipe => recipe.lactose === 0)
                                                                      .filter(recipe => recipe.gluten === 1)
          setRecipeNoFourNoVeganNoLactoseWithGluten(recipeNoFourNoVeganNoLactoseWithGluten);

          const recipeNoFourNoVeganNoLactoseNoGluten = recipes.filter(recipe => recipe.four === 0)
                                                                      .filter(recipe => recipe.vegan === 0)
                                                                      .filter(recipe => recipe.lactose === 0)
                                                                      .filter(recipe => recipe.gluten === 0)
          setRecipeNoFourNoVeganNoLactoseNoGluten(recipeNoFourNoVeganNoLactoseNoGluten);

          const recipeWithFourWithVeganNoLactoseWithGluten = recipes.filter(recipe => recipe.four === 1)
                                                                      .filter(recipe => recipe.vegan === 1)
                                                                      .filter(recipe => recipe.lactose === 0)
                                                                      .filter(recipe => recipe.gluten === 1)
          setRecipeWithFourWithVeganNoLactoseWithGluten(recipeWithFourWithVeganNoLactoseWithGluten);

          const recipeWithFourNoVeganNoLactoseWithGluten = recipes.filter(recipe => recipe.four === 1)
                                                                      .filter(recipe => recipe.vegan === 0)
                                                                      .filter(recipe => recipe.lactose === 0)
                                                                      .filter(recipe => recipe.gluten === 1)
          setRecipeWithFourNoVeganNoLactoseWithGluten(recipeWithFourNoVeganNoLactoseWithGluten);

          const recipeWithFourNoVeganWithLactoseWithGluten = recipes.filter(recipe => recipe.four === 1)
                                                                      .filter(recipe => recipe.vegan === 0)
                                                                      .filter(recipe => recipe.lactose === 1)
                                                                      .filter(recipe => recipe.gluten === 1)
          setRecipeWithFourNoVeganWithLactoseWithGluten(recipeWithFourNoVeganWithLactoseWithGluten);

          const recipeNoFourWithVeganWithLactoseNoGluten = recipes.filter(recipe => recipe.four === 0)
                                                                      .filter(recipe => recipe.vegan === 1)
                                                                      .filter(recipe => recipe.lactose === 1)
                                                                      .filter(recipe => recipe.gluten === 0)
          setRecipeNoFourWithVeganWithLactoseNoGluten(recipeNoFourWithVeganWithLactoseNoGluten);

          const recipeWithFourNoVeganWithLactoseNoGluten = recipes.filter(recipe => recipe.four === 1)
                                                                      .filter(recipe => recipe.vegan === 0)
                                                                      .filter(recipe => recipe.lactose === 1)
                                                                      .filter(recipe => recipe.gluten === 0)
          setRecipeWithFourNoVeganWithLactoseNoGluten(recipeWithFourNoVeganWithLactoseNoGluten);

          const recipeNoFourWithVeganNoLactoseWithGluten = recipes.filter(recipe => recipe.four === 0)
                                                                      .filter(recipe => recipe.vegan === 1)
                                                                      .filter(recipe => recipe.lactose === 0)
                                                                      .filter(recipe => recipe.gluten === 1)
          setRecipeNoFourWithVeganNoLactoseWithGluten(recipeNoFourWithVeganNoLactoseWithGluten);

          const recipeNoFourWithVeganNoLactoseNoGluten = recipes.filter(recipe => recipe.four === 0)
                                                                      .filter(recipe => recipe.vegan === 1)
                                                                      .filter(recipe => recipe.lactose === 0)
                                                                      .filter(recipe => recipe.gluten === 0)
          setRecipeNoFourWithVeganNoLactoseNoGluten(recipeNoFourWithVeganNoLactoseNoGluten);

          const recipeNoFourNoVeganWithLactoseNoGluten = recipes.filter(recipe => recipe.four === 0)
                                                                      .filter(recipe => recipe.vegan === 0)
                                                                      .filter(recipe => recipe.lactose === 1)
                                                                      .filter(recipe => recipe.gluten === 0)
          setRecipeNoFourNoVeganWithLactoseNoGluten(recipeNoFourNoVeganWithLactoseNoGluten);
        })
    }, [])

  return (
      <div style={{marginTop:'20px', display:'flex', flexDirection:'row', justifyContent:'space-evenly'}}>
            <Table style={{background:'wheat', padding:'10px', borderRadius:'5px', width:'41%'}}>
              <thead>
                <tr>
                  <th className='centre'> Les  catégories des recettes </th>
                  <th className='centre' > valeur </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='text' > Four/Vegan/Lactose/Gluten </td>
                  <td className='centre' >{recipeWithFourWithVeganWithLactoseWithGluten.length}</td>
                </tr>
                <tr>
                  <td className='text' > Four/Vegan/Lactose </td>
                  <td className='centre' >{recipeWithFourWithVeganWithLactoseNoGluten.length}</td>
                </tr>
                <tr>
                  <td className='text' > Four/Vegan/Gluten </td>
                  <td className='centre' >{recipeWithFourWithVeganNoLactoseWithGluten.length}</td>
                </tr>
                <tr>
                  <td className='text' > Four/Lactose/Gluten </td>
                  <td className='centre' >{recipeWithFourNoVeganWithLactoseWithGluten.length}</td>
                </tr>
                <tr>
                  <td className='text' > Vegan/Lactose/Gluten </td>
                  <td className='centre' >{recipeNoFourWithVeganWithLactoseWithGluten.length}</td>
                </tr>
                <tr>
                  <td className='text' > Four/Vegan </td>
                  <td className='centre' >{recipeWithFourWithVeganNoLactoseNoGluten.length}</td>
                </tr>
                <tr>
                  <td className='text' > Four/Gluten </td>
                  <td className='centre' >{recipeWithFourNoVeganNoLactoseWithGluten.length}</td>
                </tr>
                <tr>
                  <td className='text' > Four/Lactose </td>
                  <td className='centre' >{recipeWithFourNoVeganWithLactoseNoGluten.length}</td>
                </tr>
              </tbody>
            </Table>
            
            <Table style={{background:'wheat', padding:'10px', borderRadius:'5px', width:'41%'}}>
              <thead>
                <tr>
                  <th className='centre'> Les  catégories des recettes </th>
                  <th className='centre' > valeur </th>
                </tr>
              </thead>
              <tbody>
              <tr>
                  <td className='text' > Lactose/Gluten </td>
                  <td className='centre' >{recipeNoFourNoVeganWithLactoseWithGluten.length}</td>
                </tr>
                <tr>
                  <td className='text' > Vegan/Lactose  </td>
                  <td className='centre' >{recipeNoFourWithVeganWithLactoseNoGluten.length}</td>
                </tr>
                <tr>
                  <td className='text' > Vegan/Gluten </td>
                  <td className='centre' >{recipeNoFourWithVeganNoLactoseWithGluten.length}</td>
                </tr>
                <tr>
                  <td className='text' > Four </td>
                  <td className='centre' >{recipeWithFourNoVeganNoLactoseNoGluten.length}</td>
                </tr>
                <tr>
                  <td className='text' > Gluten </td>
                  <td className='centre' >{recipeNoFourNoVeganNoLactoseWithGluten.length}</td>
                </tr>
                <tr>
                  <td className='text' > Vegan </td>
                  <td className='centre' >{recipeNoFourWithVeganNoLactoseNoGluten.length}</td>
                </tr>
                <tr>
                  <td className='text' > Lactose </td>
                  <td className='centre' >{recipeNoFourNoVeganWithLactoseNoGluten.length}</td>
                </tr>
                <tr>
                  <td className='text' > Rien </td>
                  <td className='centre' >{recipeNoFourNoVeganNoLactoseNoGluten.length}</td>
                </tr>
              </tbody>
            </Table>
    </div>
  )
}

export default StatMultifiltre
