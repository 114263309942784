import React from "react";
import "./index.css";
import SideBar from "../../components/side-bar";
import NavBar from "../../components/nav-bar";
import { Outlet } from "react-router";

const Ingredients = () => {
  return (
    <div className="page">
      <SideBar />
      <div>
        <div className="nav-bar-content-container">
          <NavBar />
          <div className="content">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Ingredients;
