import './index.css';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Pagination } from '@mui/material';
import * as jokesServices from "../../services/jokes";
import swal from 'sweetalert';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';

const TableJokes = (props) => {
    const [jokes, setJokes] = useState(props.jokes);

    // const [checkedItems, setCheckedItems] = useState(0);

    let [countCheckedItems, setCountCheckedItems] = useState(0);

    const headers = JSON.parse(localStorage.getItem("userInfo")) && JSON.parse(localStorage.getItem("userInfo")).isAdmin === 1 ?
                    ['Check', 'Id', 'Questions','Answers']:
                    ['Id', 'Questions', 'Answers'];
                    
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(9);
    
    const [jokesChecked, setJokesChecked] = useState([])

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    var currentItems = props.jokes.slice(indexOfFirstItem, indexOfLastItem);
  
    useEffect(() => {
        setCountCheckedItems(jokes.filter(item => item.checked === true).length);
    }, [jokes]);

    const handleInputChange = (e) => {
      const {value, checked} = e.target
      if(checked){
        setJokesChecked(pre => [...pre, parseInt(value)])
      }
      else{
        setJokesChecked(pre => {
          return [...pre.filter(item => item !== parseInt(value))]
        })
      }
      console.log(setJokesChecked)
    }

    const handleDelete = async (id) => {
      swal({
        title: "Are you sure?",
        text: `Ce joke sera supprimé définitivement`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then( async function (isOkay) {
        if (isOkay) {
            jokesServices.deleteJoke(id)
            .then(res => {
              if(res.status === 200){
                swal("Joke supprimé!", "Suppression avec succès", "success");
                setTimeout(()=>{window.location.reload(false)},500);
                }
            })
            .catch(err => {
              swal("Nope", "Une erreur s'est produite", "error");
              throw new Error(err)
            })
        }
    });
    };

    const handleDeleteAll = () => {
      swal({
        title: "Are you sure?",
        text: `Ces jokes seront supprimés définitivement`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then( async function (isOkay) {
        if (isOkay) {
          jokesChecked.forEach(joke => {
            jokesServices.deleteJoke(joke)
            .then(res => {
              if(res.status === 200){
                swal(`Jokes supprimés!`, `Suppression des jokes avec succès!`, "success");
                setTimeout(()=>{window.location.reload(false)},500);
                }
            })
            .catch(err => {
              swal("Nope", "Une erreur s'est produite", "error");
              throw new Error(err)
            })
          })
        }
    });
    };

    const handleChange = (event, value) => {
      setCurrentPage(value);
    };
    
    return (
      <>
      {jokesChecked.length >= 2 ? <div className='buttonContainer m-1'>
            <Button variant="contained" color="error" margin='normal' onClick={handleDeleteAll}>
                Suppression multiple
            </Button>
        </div>:null}
      <div id="tableUsersPage">
      <TextField
        id="search-bar"
        className="text"
        onInput={(e) => {
          setCurrentPage(e.target.value);
        }}
        label="page number"
        variant="outlined"
        size="small"
        value={currentPage}
        style={{marginBottom:'10px'}}
      />
        <table className='table1'>
          <tr className='tb1'>
            {headers.map(head => <th>{head}</th>)}
            <th></th>
          </tr>
          <tbody>
            {currentItems.map((item) => (
              <tr key={item.id}>
                {JSON.parse(localStorage.getItem("userInfo")) && JSON.parse(localStorage.getItem("userInfo")).isAdmin === 1 ?
                <td style={{width:"15px"}}>
                  <input type="checkbox" value={item.id} checked={item.checked} onChange={handleInputChange} />
                </td>:
                <></>}
                <td>{item.id}</td>
                <td style={{width:"40%"}}>{item.question}</td>
                <td style={{width:"40%"}}>{item.answer}</td>
                {JSON.parse(localStorage.getItem("userInfo")) && JSON.parse(localStorage.getItem("userInfo")).isAdmin === 1 ? 
                <td className='btn2'>
                  <Link className='btn' to={`edit/${item.id}`}><EditIcon /></Link>
                  <button className='btn' onClick={() => handleDelete(item.id)}><DeleteIcon /></button>
                </td>:
                <></>}
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination className='pagi'
         count={Math.ceil(props.jokes.length / itemsPerPage)}
         page={currentPage}
         variant="outlined"
         shape='rounded'
         onChange={handleChange}
         style={{display:"flex"}}
        />
      </div>
      </>
    );    
};

export default TableJokes;