import { Outlet } from "react-router-dom";
import Logo from './components/logo';

function Auth() {
    return (
        <div className=" page">
            <div>
                <Logo />
            </div>
            <div className="form shadow">
                <Outlet />
            </div>
        </div>);
}

export default Auth;


// import { useState } from 'react';
// import { connect } from 'react-redux';
// import { login, logout } from '../../redux/actions/authActions';

// const Login = ({ isAuthenticated, username, login, logout }) => {
//     const [formData, setFormData] = useState({
//         username: '',
//         password: ''
//     });

//     const handleChange = (event) => {
//         setFormData({
//             ...formData,
//             [event.target.name]: event.target.value
//         });
//     }

//     const handleSubmit = (event) => {
//         event.preventDefault();
//         login(formData.username, formData.password);
//     }

//     return (
//         <div>
//             {isAuthenticated ? (
//                 <div>
//                     <p>Welcome, {username}</p>
//                     <button onClick={logout}>Logout</button>
//                 </div>
//             ) : (
//                 <form onSubmit={handleSubmit}>
//                     <input type="text" name="username" placeholder="Username" onChange={handleChange} value={formData.username} />
//                     <input type="password" name="password" placeholder="Password" onChange={handleChange} value={formData.password} />
//                     <button type="submit">Login</button>
//                 </form>
//             )}
//         </div>
//     );
// }

// const mapStateToProps = (state) => {
//     return {
//         isAuthenticated: state.auth.isAuthenticated,
//         username: state.auth.username
//     }
// }

// const mapDispatchToProps = (dispatch) => {
//     return {
//         login: (username, password) => dispatch(login(username, password)),
//         logout: () => dispatch(logout())
//     }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(Login);


