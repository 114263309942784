import React, { useState, useEffect } from 'react';
import "./profil.css";
import {MDBCard,MDBCardText,MDBCardBody,MDBTypography,} from 'mdb-react-ui-kit';
import * as userServices from "../../services/users";

import Avatar from 'react-avatar-edit';
import img from "../../assets/img.png";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
        

const ProfilUser=() => {
  const [userData, setUserData] = useState();
  
  const [image, setImage] = useState('');
  const [imagecrop, setImageCrop] = useState(false);
  const [src, setSrc] = useState(false);
  const [profile, setProfile] = useState([]);
  const [pview, setPview] = useState(false);

  const profileFinal = profile.map((item) => item.pview);

  const onClose = () => {
    setPview(null);
  };


  const onCrop = (view) => {
    setPview(view);
  };

  const saveCropImage = () => {
    setProfile([...profile, { pview }]);
    setImageCrop(false);
  }

  useEffect(()=>{
    const userId = sessionStorage.getItem('userId');

    if(userId){
      userServices.getUser(userId)
      .then(user => {
        if(user)
          setUserData(user[0]);
      })
      .catch(error => console.error(error));
    }
    console.log(userId)
  }, []);

    return (
      <div className='profil'>
        <MDBTypography tag='h1' style={{color:"#fff"}}>Mon Profil</MDBTypography>
        <MDBCard style={{ borderRadius: '10px', background:"#fff", padding:"20px" }} className='profile_image text-center p-4'>
          {userData && <MDBCardBody className="p-4">
            <span className="overlay"></span>

            <div className='flex flex-column justify-content-center align-items-center'>
              <img 
                style={{
                  width:'150px',
                  height:'150px',
                  borderRadius:'50%',
                  objectFit:'cover'
                }}
                onClick={() => setImageCrop(true)}
                src={profileFinal.length ? profileFinal : img}
                alt="" 
              />
              
              <hr />
              <MDBCardText className="small" style={{display:"flex", flexDirection:'row', justifyContent:'space-between', color:"#333", fontSize:"18px", fontWeight:"500"}}>
                Nom: {userData.username}
              </MDBCardText>
              <hr />
              <MDBCardText className="small" style={{display:"flex", flexDirection:'row', justifyContent:'space-between',  color:"#333", fontSize:"18px", fontWeight:"500"}}>
                Adresse e-mail: {userData.email}
              </MDBCardText>
              <hr />

                <Dialog
                  visible={imagecrop}
                  header={ () => (
                      <p htmlFor='' className='text-2xl font-semibold textcolor' id='texte' >
                        Update Profile
                      </p>
                    )}
                  onHide={()=> setImageCrop(true)}
                  className='dialogue'
                >
                  <div className='confirmation-content flex flex-column align-items-center'>
                    <Avatar 
                      width={500}
                      height={250}
                      onCrop={onCrop}
                      onClose={onClose}
                      src={src}
                      shadingColor={"#000"}
                      backgroundColor={"#fff"}
                    />

                    <div className='flex flex-column align-items-center mt-5 w-12'>
                      <div className='flex justify-content-around w-12 mt-4'>
                        <Button 
                          onClick={saveCropImage}
                          label='save'
                          icon='pi pi-check'
                          className='bouton'
                        />
                      </div>
                    </div>
                  </div>
                </Dialog>
                <InputText 
                  type='file'
                  accept='/image/*'
                  style={{display:'none'}}
                  onChange={ (event)=> {
                    const file = event.target.files[0];
                    if(file && file.type.substring(0,5) === 'image'){
                      setImage(file);
                    }
                    else{
                      setImage(null);
                    }
                  } }
                  
                />
            </div>
          </MDBCardBody>}
        </MDBCard>
      </div>
    );
  }

export default ProfilUser;
