import axios from "axios";
import * as config from "../config/config.js";
import axiosClient from "../api/axio.client.js";
import jwtDecode from "jwt-decode";

// userServices.getAllUsers().then(x => { x.map(y => console.log(y)) });
export const getAllUsers = async () => {
  try {
    const response = await axiosClient.get(`${config.baseUrl}/users/get-all`);
    return response.data;
  } catch (error) {
    return [];
  }
};

// userServices.getUser(4).then(x => console.log(x[0]));
export const getUser = async (id) => {
  try {
    const response = await axiosClient.get(
      `${config.baseUrl}/users/get-by-id/${id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// userServices.createUser(username, email, password, confirmed, blocked);
export const createUser = async (
  username,
  email,
  password,
  isAdmin,
  confirmed,
  blocked
) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/auth/register`,
      {
        username: username,
        email: email,
        password: password,
        isAdmin: isAdmin,
        confirmed: confirmed,
        blocked: blocked,
      },
      {
        headers: {
          "Cache-Control": "no-cache",
        },
      }
    );
    return response;
  } catch (error) {
    return [];
  }
};

// userServices.updateUser(username, email, password, confirmed, blocked, id);
export const updateUser = async (
  username,
  email,
  isAdmin,
  confirmed,
  blocked,
  id
) => {
  try {
    const response = await axiosClient.put(
      `${config.baseUrl}/users/update`,
      {
        username: username,
        email: email,
        isAdmin: isAdmin,
        confirmed: confirmed,
        blocked: blocked,
        id: id,
      },
      {
        headers: {
          "Cache-Control": "no-cache",
        },
      }
    );
    return response;
  } catch (error) {
    return [];
  }
};

// userServices.deleteUser(id);
export const deleteUser = async (id) => {
  try {
    const response = await axiosClient.delete(
      `${config.baseUrl}/users/delete/${id}`
    );
    return response;
  } catch (error) {
    return [];
  }
};
// userServices.login(username, password);
export const login = async (username, password) => {
  try {
    const response = await axios.post(`${config.baseUrl}/auth/login`, {
      username: username,
      password: password,
    });

    if (response.status === 201) {
      sessionStorage.setItem("userId", response.data.data.id);
      sessionStorage.setItem("jwt", JSON.stringify(response.data.token));
      const userInfo = jwtDecode(response.data.token);
      localStorage.setItem("userInfo", JSON.stringify(userInfo.result));
      // isAuthenticated = true;
      //we make an setInterval to refresh the token before its go out
    }

    return true;
  } catch (error) {
    return false;
  }
};

export const logout = () => {
  // isAuthenticated = false;
  sessionStorage.removeItem("userId");
  sessionStorage.removeItem("jwt");
};
