import "./index.css";
import NavBar from "../../components/nav-bar";
import SideBar from "../../components/side-bar";
import BackButton from "../../components/backButton";
import Title from "../../components/title";
import LayersClearRoundedIcon from "@mui/icons-material/LayersClearRounded";

const NotFound = () => {
  return (
    <div className="page">
      <SideBar />
      <div>
        <div className="nav-bar-content-container">
          <NavBar />
          <div className="content">
            <div id="backButton">
              <BackButton />
            </div>
            <LayersClearRoundedIcon sx={{ fontSize: 80 }} />
            <Title title="Page non trouvée" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
