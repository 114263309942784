import "./index.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  Alert,
  Stack,
} from "@mui/material";
import * as userServices from "../../../services/users";

import Title from "../../../components/title";
import IconButton from "@mui/material/IconButton";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import swal from "sweetalert";

const Connexion = ({ authfunc }) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [log, setLog] = useState(true);

  const [usernames, setUsernames] = useState("");
  const [email, setEmail] = useState("");
  const [passwords, setPasswords] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [blocked, setBlocked] = useState(false);
  useEffect(() => {
    const token = sessionStorage.getItem("jwt");
    if (token) {
      navigate("/");
    }
  }, [navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    sessionStorage.setItem("username", username);
    sessionStorage.setItem("password", password);
    const res = await userServices.login(username, password);
    if (res) {
      authfunc(true);
      navigate("/");
    } else {
      //raise error
      setError(true);
      console.log("error");
    }
  };

  const handleReturnClick = () => {
    setLog(true);
  };

  const handleSignup = () => {
    setLog(false);
  };

  //////////////////////////////////register//////////////////////////////////////////////
  const handleSubmits = (e) => {
    e.preventDefault();
    if (!usernames || !email || !passwords || !confirmPassword) {
      swal("Nope", "Veuillez remplir toutes les cases!", "error");
    } else if (passwords !== confirmPassword) {
      swal("Nope", "Veuillez indiquer les 2 mots de passe identiques", "error");
    } else {
      userServices
        .createUser(usernames, email, passwords, isAdmin, confirmed, blocked)
        .then((res) => {
          if (res.data.status === 200) {
            swal(
              "Utilisateur crée!",
              "Création d'utilisateur avec succès",
              "success"
            );
            setLog(true);
          }
        })
        .catch((err) => {
          swal("Nope", "Une erreur s'est produite", "error");
          console.log(err);
          throw new Error(err);
        });
    }
  };

  return (
    <>
      {log === true ? (
        <div>
          <Stack sx={{ m: 1 }}>
            {error ? (
              <Alert severity="error">
                <b>Error:</b> The username or password is incorrect.
              </Alert>
            ) : (
              <></>
            )}
          </Stack>
          <form className=" form p-10" onSubmit={handleSubmit}>
            <TextField
              id="outlined-start-adornment"
              sx={{ m: 1 }}
              label="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <FormControl sx={{ m: 1 }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type="password"
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            <Box sx={{ "& button": { m: 1 } }}>
              <Button
                variant="contained"
                color="success"
                type="submit"
                margin="normal"
                size="small"
              >
                Login
              </Button>
            </Box>
            <Box sx={{ "& button": { m: 1 } }}>
              <Button
                variant="contained"
                color="success"
                margin="normal"
                size="small"
                onClick={handleSignup}
              >
                Sign up
              </Button>
            </Box>
          </form>
        </div>
      ) : (
        <div>
          <form className=" form p-10" onSubmit={handleSubmits}>
            <div className="container">
              <IconButton
                color="success"
                aria-label="back button"
                component="label"
                onClick={handleReturnClick}
              >
                <ArrowCircleLeftOutlinedIcon fontSize="large" />
              </IconButton>
              <Title className="div2" title={"Inscription"} />
            </div>
            <TextField
              id="outlined-start-adornment"
              sx={{ m: 1 }}
              label="Username"
              value={usernames}
              onChange={(e) => setUsernames(e.target.value)}
            />

            <TextField
              id="outlined-start-adornment"
              sx={{ m: 1 }}
              type="email"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <FormControl sx={{ m: 1 }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type="password"
                label="Password"
                value={passwords}
                onChange={(e) => setPasswords(e.target.value)}
              />
            </FormControl>

            <FormControl sx={{ m: 1 }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-confirm-password">
                Confirmation
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-confirm-password"
                type="password"
                label="Confirmation"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </FormControl>

            <Box sx={{ "& button": { m: 1 } }}>
              <Button
                variant="contained"
                color="success"
                type="submit"
                margin="normal"
                size="small"
              >
                Sign up
              </Button>
            </Box>
          </form>
        </div>
      )}
    </>
  );
};

export default Connexion;
