import React from "react";
import NavBar from "../../components/nav-bar";
import SideBar from "../../components/side-bar";
import ProfilUser from "../../components/Profil/profil";

const Profil = () => {
  return (
    <div className="page">
      <SideBar />
      <div>
        <div className="nav-bar-content-container">
          <NavBar />
          <div className="content">
            <ProfilUser />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profil;
