import "./index.css";
import { useState } from "react";
import BackButton from "../../../components/backButton";
import Title from "../../../components/title";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useEffect } from "react";
import * as jokeServices from "../../../services/jokes";
import { useParams } from "react-router-dom";
import swal from "sweetalert";

const EditJoke = () => {
  const [title, setTitle] = useState("Jokes");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const { id } = useParams();

  useEffect(() => {
    console.log(id);
    jokeServices.getJoke(id).then((joke) => {
      setQuestion(joke[0]?.question);
      setAnswer(joke[0]?.answer);
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!question || !answer) {
      swal("Nope", "Veuillez remplir toutes les cases!", "error");
    } else {
      jokeServices
        .updateJoke(question, answer, id)
        .then((res) => {
          if (res.status === 200) {
            swal("Joke mis à jour!", "Mise à jour avec succès", "success");
          }
        })
        .catch((err) => {
          swal("Nope", "Une erreur s'est produite", "error");
          throw new Error(err);
        });
    }
  };

  return (
    <div>
      <div className="back">
        <BackButton />
      </div>
      <div className="width">
        <Title title={title} />
        <form
          onSubmit={handleSubmit}
          className=" forme shadow p-1 "
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="column">
            <div className="texte">
              <TextField
                sx={{
                  width: 600,
                }}
                type="text"
                placeholder="Question"
                variant="outlined"
                margin="normal"
                multiline
                maxRows={1000}
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="texte">
              <TextField
                sx={{
                  width: 600,
                }}
                type="text"
                placeholder="Answer"
                variant="outlined"
                margin="normal"
                multiline
                maxRows={1000}
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
              />
            </div>
          </div>
          <div className="mt-2">
            <Button
              variant="contained"
              color="success"
              type="submit"
              margin="normal"
            >
              Valider
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default EditJoke;
