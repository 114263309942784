import * as config from "../config/config.js";
import axiosClient from "../api/axio.client.js";
import axiosClientFile from "../api/axios.file.js";

// recipesService.getAllRecipes().then(x => { x.map(y => console.log(y)) });
export const getAllRecipes = async () => {
  try {
    const response = await axiosClient.get(`${config.baseUrl}/recipes/get-all`);
    return response.data;
  } catch (error) {
    return [];
  }
};

// recipesService.getRecipe(4).then(x => console.log(x[0]));
export const getRecipe = async (id) => {
  try {
    const response = await axiosClient.get(
      `${config.baseUrl}/recipes/get-by-id/${id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// recipesService.createRecipe(recipe_type, recipe_name, steps, link, nb_personnes, vegan,
// four, cooking_time, standing_time, preparation_time, Author, url_media, ingredients_quantities);
export const createRecipe = async (
  recipe_type,
  recipe_name,
  steps,
  link,
  nb_personnes,
  vegan,
  four,
  cooking_time,
  standing_time,
  preparation_time,
  Author,
  url_media,
  ingredients_quantities
) => {
  try {
    const response = await axiosClient.post(
      `${config.baseUrl}/recipes/create`,
      {
        recipe_type: recipe_type,
        recipe_name: recipe_name,
        steps: steps,
        link: link,
        nb_personnes: nb_personnes,
        vegan: vegan,
        four: four,
        cooking_time: cooking_time,
        standing_time: standing_time,
        preparation_time: preparation_time,
        Author: Author,
        url_media: url_media,
        ingredients_quantities: ingredients_quantities,
      },
      {
        headers: {
          "Cache-Control": "no-cache",
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    return [];
  }
};

export const addRecipe = async (formData) => {
  try {
    const response = await axiosClientFile.post(
      `${config.baseUrl}/recipes/add-recipe`,
      formData,
      {
        headers: {
          "Cache-Control": "no-cache",
          "Content-type": "multipart/form-data",
        },
      }
    );
    // console.log(response.data.insertId);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateOneRecipe = async (formData) => {
  try {
    const response = await axiosClientFile.put(
      `${config.baseUrl}/recipes/update-recipe`,
      formData,
      {
        headers: {
          "Cache-Control": "no-cache",
          "Content-type": "multipart/form-data",
        },
      }
    );
    console.log(response.data.serverStatus);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
// recipesService.updateRecipe(recipe_type, recipe_name, steps, link, nb_personnes, vegan,
// four, cooking_time, standing_time, preparation_time, Author, url_media, ingredients_quantities, id);
export const updateRecipe = async (
  recipe_type,
  recipe_name,
  steps,
  link,
  nb_personnes,
  vegan,
  four,
  cooking_time,
  standing_time,
  preparation_time,
  Author,
  url_media,
  ingredients_quantities,
  id
) => {
  try {
    const response = await axiosClient.put(
      `${config.baseUrl}/recipes/update`,
      {
        recipe_type: recipe_type,
        recipe_name: recipe_name,
        steps: steps,
        link: link,
        nb_personnes: nb_personnes,
        vegan: vegan,
        four: four,
        cooking_time: cooking_time,
        standing_time: standing_time,
        preparation_time: preparation_time,
        Author: Author,
        url_media: url_media,
        ingredients_quantities: ingredients_quantities,
        id: id,
      },
      {
        headers: {
          "Cache-Control": "no-cache",
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    return [];
  }
};

// recipesService.deleteRecipe(id);
export const deleteRecipe = async (id) => {
  try {
    const response = await axiosClient.delete(
      `${config.baseUrl}/recipes/delete/${id}`
    );
    return response;
  } catch (error) {
    return [];
  }
};
