import './index.css';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Pagination } from '@mui/material';
import * as userServices from "../../services/users";
import swal from 'sweetalert';
import { Button } from '@mui/material';
import {TextField} from '@mui/material';
const TableUsers = (props) => {
    const [user, setData] = useState(props.user);

    // const [checkedItems, setCheckedItems] = useState(0);

    let [countCheckedItems, setCountCheckedItems] = useState(0);

    const headers = JSON.parse(localStorage.getItem("userInfo")) && JSON.parse(localStorage.getItem("userInfo")).isAdmin === 1 ?
                    ['Check', 'Id', 'Username','Email']:
                    ['Id', 'Username', 'Email'];
                    
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    
    const [userChecked, setUserChecked] = useState([])

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    var currentItems = props.user.slice(indexOfFirstItem, indexOfLastItem);
  
    useEffect(() => {
        setCountCheckedItems(user.filter(item => item.checked === true).length);
    }, [user]);

    const handleInputChange = (e) => {
      const {value, checked} = e.target
      if(checked){
        setUserChecked(pre => [...pre, parseInt(value)])
      }
      else{
        setUserChecked(pre => {
          return [...pre.filter(item => item !== parseInt(value))]
        })
      }
      console.log(userChecked)
    }

    const handleDelete = async (id, name) => {
      swal({
        title: "Are you sure?",
        text: `${name} sera supprimé définitivement`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then( async function (isOkay) {
        if (isOkay) {
            userServices.deleteUser(id)
            .then(res => {
              if(res.status === 200){
                swal("Utilisateur supprimé!", "Suppression avec succès", "success");
                setTimeout(()=>{window.location.reload(false)},500);
                }
            })
            .catch(err => {
              swal("Nope", "Une erreur s'est produite", "error");
              throw new Error(err)
            })
        }
    });
    };

    const handleDeleteAll = () => {
      swal({
        title: "Are you sure?",
        text: `Ces utilisateurs seront supprimés définitivement`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then( async function (isOkay) {
        if (isOkay) {
          userChecked.forEach(user => {
            userServices.deleteUser(user)
            .then(res => {
              if(res.status === 200){
                swal(`Utilisateurs supprimés!`, `Suppression des utilisateurs avec succès!`, "success");
                setTimeout(()=>{window.location.reload(false)},500);
                }
            })
            .catch(err => {
              swal("Nope", "Une erreur s'est produite", "error");
              throw new Error(err)
            })
          })
        }
    });
    };

    const handleChange = (event, value) => {
      setCurrentPage(value);
    };
    
    return (
      <>
      {userChecked.length >= 2 ? <div className='buttonContainer m-1'>
            <Button variant="contained" color="error" margin='normal' onClick={handleDeleteAll}>
                Suppression multiple
            </Button>
        </div>:null}
      <div id="tableUsersPage">
      <TextField
        id="search-bar"
        className="text"
        onInput={(e) => {
          setCurrentPage(e.target.value);
        }}
        label="page number"
        variant="outlined"
        size="small"
        value={currentPage}
        style={{marginBottom:'10px'}}
      />
        <table className='table1'>
          <tr className='tb1'>
            {headers.map(head => <th>{head}</th>)}
            <th></th>
          </tr>
          <tbody>
            {currentItems.map((item) => (
              <tr key={item.id}>
                {JSON.parse(localStorage.getItem("userInfo")) && JSON.parse(localStorage.getItem("userInfo")).isAdmin === 1 ?<td>
                  <input type="checkbox" value={item.id} checked={item.checked} onChange={handleInputChange} />
                </td>:
                <></>}
                <td>{item.id}</td>
                <td>{item.username}</td>
                <td>{item.email}</td>
                {JSON.parse(localStorage.getItem("userInfo")) && JSON.parse(localStorage.getItem("userInfo")).isAdmin === 1 ? <td className='btn2'>
                  <Link className='btn' to={`edit/${item.id}`}><EditIcon /></Link>
                  <button className='btn' onClick={() => handleDelete(item.id, item.username)}><DeleteIcon /></button>
                </td>:
                <></>}
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination className='pagi'
         count={Math.ceil(props.user.length / itemsPerPage)}
         page={currentPage}
         variant="outlined"
         shape='rounded'
         onChange={handleChange}
         style={{display:"flex"}}
        />
      </div>
      </>
    );    
};

export default TableUsers;