import * as config from "../config/config.js";

import axiosClient from "../api/axio.client.js";
export const addRecipeIdAndIngredientId = async (recipe_id, ingredient_id) => {
  try {
    const response = await axiosClient.post(
      `${config.baseUrl}/recipeHasIngredient/add-all-recipe-id-and-ingredient-id`,
      { recipe_id: recipe_id, ingredient_id: ingredient_id },
      {
        headers: {
          "Cache-Control": "no-cache",
        },
      }
    );
    return response;
  } catch (error) {
    return [];
  }
};

export const deteleRecipeIngredientOfARecipe = async (
  ingredient_id,
  recipe_id
) => {
  try {
    const response = await axiosClient.delete(
      `${config.baseUrl}/recipeHasIngredient/delete-ingredient-of-the-recipe/${ingredient_id}/${recipe_id}`,
      {
        headers: {
          "Cache-Control": "no-cache",
        },
      }
    );
    return response;
  } catch (error) {
    return [];
  }
};

export const getRecipeByIngredient = async (ingredientId) => {
  try {
    const response = await axiosClient.get(
      `${config.baseUrl}/recipeHasIngredient/get-all-recipe-id?ingredientId=${ingredientId}`,
      {
        headers: {
          "Cache-Control": "no-cache",
        },
      }
    );
    return response.data;
  } catch (error) {
    return [];
  }
};

export const getRecipeIDontContainIngredientId = async (ingredientId) => {
  try {
    const response = await axiosClient.get(
      `${config.baseUrl}/recipeHasIngredient/get-all-recipe-id-not-linked-with-ingredientId/${ingredientId}`,
      {
        headers: {
          "Cache-Control": "no-cache",
        },
      }
    );
    return response.data;
  } catch (error) {
    return [];
  }
};

export const getIngredientByRecipe = async (recipeId) => {
  try {
    const response = await axiosClient.get(
      `${config.baseUrl}/recipeHasIngredient/get-all-ingredient-id?recipeId=${recipeId}`,
      {
        headers: {
          "Cache-Control": "no-cache",
        },
      }
    );
    return response.data;
  } catch (error) {
    return [];
  }
};

export const getAllRecipeAndHisIngredients = async () => {
  try {
    const response = await axiosClient.get(
      `${config.baseUrl}/recipeHasIngredient/get-all-recipe-and-its-ingredients`
    );
    return response.data.data;
  } catch (error) {
    return error;
  }
};
