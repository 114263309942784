import React, { useState } from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

const CreateNewItemButton = (props) => {
  const [isCreating, setIsCreating] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setIsCreating(true);
    if (props.title === "+ Add new User") {
      //qqch
      navigate("/editUser", { state: { isEditMode: false } }); //manque item);
    } else if (props.title === "+ Add new Ingredient") {
      //autre chose
      navigate("create"); //manque item
    } else if (props.title === "+Add new Recipe") {
      //autre chose
      navigate("create"); //manque item
    } else if (props.title === "+Add new User") {
      //autre chose
      navigate("create"); //manque item
    } else if (props.title === "+Add new Joke") {
      //autre chose
      navigate("create"); //manque item
    }
    // Trigger the creation of a new item here
  };

  return (
    <div className="buttonContainer m-1">
      <Button
        variant="contained"
        color="success"
        onClick={handleClick}
        margin="normal"
      >
        {props.title}
      </Button>
    </div>
  );
};

export default CreateNewItemButton;
