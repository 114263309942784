import React from "react";
import { useEffect, useState } from "react";
import * as ingredientServices from "../../../services/ingredients";

const TotalIngredient = () => {
  const [nbrIngredient, setNbrIngredient] = useState();

  useEffect(()=>{
    ingredientServices.getAllIngredients()
    .then(ingredients => {
      setNbrIngredient(ingredients.length)
    })
    console.log(nbrIngredient)
  }, [nbrIngredient])

  return (
    <div className=" circle text-white text-center p-2">
      <div className="my-5">
      <h2>Ingrédients</h2>
      <h1 className='display-5'>{nbrIngredient}</h1>
      </div>
    </div>
    );
}

export default TotalIngredient;