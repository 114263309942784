import React from "react";
import "./index.css";
import CreateNewItemButton from "../../../components/createButton";
import Tablerecipe from "../../../components/table/recipes";
import Title from "../../../components/title";
import { useEffect, useState } from "react";
import * as recipeService from "../../../services/recipes";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

function ShowRecipes() {
  const [recipes, setRecipes] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    recipeService.getAllRecipes().then((recipes) => setRecipes(recipes));
  }, []);
  const title = "Recipes";
  return (
    <div>
      <Title title={title} />
      <div>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <TextField
            id="input-with-sx"
            label="Search"
            variant="standard"
            style={{ marginBottom: "10px" }}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Box>
      </div>
      {JSON.parse(localStorage.getItem("userInfo")) &&
      JSON.parse(localStorage.getItem("userInfo"))?.isAdmin === 1 ? (
        <CreateNewItemButton title="+Add new Recipe" />
      ) : (
        <></>
      )}
      <Tablerecipe
        recipe={recipes?.filter((item) =>
          item.recipe_name.toLowerCase().includes(search.toLowerCase())
        )}
      />
    </div>
  );
}
export default ShowRecipes;
