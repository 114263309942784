import React, { useState, useEffect } from "react";
import "./index.css";
import BackButton from "../../../components/backButton";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import { Button } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Title from "../../../components/title";
import DeleteIcon from "@mui/icons-material/Delete";
import Select from "react-select";

import * as recipeService from "../../../services/recipes";
import * as recipeIdAndIngredientIdService from "../../../services/recipeHasIngredient";

import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { getAllIngredients } from "../../../services/ingredients";
import { Link } from "react-router-dom";

const Createrecipe = () => {
  const title = "Create new recipe";
  const [recipe_name, setRecipe_name] = useState("");
  const [nb_personnes, setNb_personnes] = useState("");
  const [steps, setSteps] = useState("");
  const [preparationTime, setpreparationTime] = useState(null);
  const [vegan, setVegan] = useState(false);
  const [cooking_time, setCooking_time] = useState(null);
  const [recipe_type, setRecipe_type] = useState([]);
  const [four, setFour] = useState(false);
  const [link, setLink] = useState("");
  const [gluten, setGluten] = useState(false);
  const [lactose, setLactose] = useState(false);
  const [url_media, setMedia] = useState(null);
  const [ingredients_quantities, setIngredients_quantities] = useState("");
  const [standing_time, setStanding_time] = useState(null);
  const [Author, setAuthor] = useState(null);

  const [data, setData] = useState("");
  const [Ingredients, setIngredients] = useState([]);

  const navigate = useNavigate();
  var recipeType = [
    {
      value: "Apéro/Entrée",
      label: "Apéro/Entrée",
    },
    {
      value: "Plat",
      label: "Plat",
    },
    {
      value: "Dessert/Goûter",
      label: "Dessert/Goûter",
    },
  ];

  useEffect(() => {
    getAllIngredients().then((ingredients) => {
      ingredients.map(
        (ingredient) => (
          // eslint-disable-next-line no-sequences
          Object.assign(ingredient, { value: ingredient.id })["ingredient"],
          // eslint-disable-next-line no-sequences
          // delete ingredient['ingredient'],
          (ingredient["label"] = ingredient.ingredient)
        )
      );
      setData(ingredients);
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("recipe_name", recipe_name);

    formData.append("nb_personnes", nb_personnes);

    formData.append("Author", Author);

    formData.append("steps", steps);

    formData.append("preparation_time", preparationTime);

    formData.append("standing_time", standing_time);

    formData.append("vegan", Number(vegan));

    formData.append("cooking_time", cooking_time);

    formData.append("recipe_type", recipe_type.toString());

    formData.append("four", Number(four));

    formData.append("link", link);

    formData.append("gluten", Number(gluten));

    formData.append("lactose", Number(lactose));

    formData.append("url_media", url_media);

    formData.append("Ingredients_quantities", ingredients_quantities);

    if (
      !recipe_name ||
      !steps ||
      !recipe_type ||
      !link ||
      !url_media ||
      !ingredients_quantities ||
      Ingredients.length === 0
    ) {
      swal("Nope", "Veuillez bien remplir les champs", "error");
    } else {
      recipeService
        .addRecipe(formData)
        .then((res) => {
          if (res.serverStatus === 2) {
            Ingredients?.forEach((ingredientId) => {
              recipeIdAndIngredientIdService
                .addRecipeIdAndIngredientId(res?.insertId, ingredientId)
                .then((res) => {
                  if (res.status === 200) {
                    swal(
                      "Recette crée!",
                      "Création de recette avec succès",
                      "success"
                    );
                    setTimeout(() => {
                      navigate("/Recipes");
                    }, 2000);
                  }
                })
                .catch((err) => {
                  swal("Nope", "Une erreur s'est produite", "error");
                  throw new Error(err);
                });
            });
          }
        })
        .catch((err) => {
          swal("Nope", "Une erreur s'est produite", "error");
          throw new Error(err);
        });
    }
  };

  const handleChange = (e) => {
    setRecipe_type(Array.isArray(e) ? e.map((x) => x?.value) : []);
  };
  const handleChangeIngredient = (e) => {
    setIngredients(Array.isArray(e) ? e.map((x) => x?.value) : []);
  };
  const handleDelete = (e) => {
    setMedia(null);
  };

  return (
    <div id="body">
      <div>
        <div className="back">
          <BackButton />
        </div>
        <div className="width">
          <Title title={title} />
          <form onSubmit={handleSubmit} className=" forme shadow p-1 ">
            <div className="">
              <div className="row">
                <div className="photo texte p-1">
                  <div className="uploader">
                    <div className="upload">
                      <Button
                        variant="contained"
                        color="error"
                        component="label"
                      >
                        <Link
                          to={"/crop"}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          crop image
                        </Link>
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="texte">
                  <TextField
                    className="w-100"
                    label="Recipe Name"
                    variant="outlined"
                    margin="normal"
                    value={recipe_name}
                    multiline
                    maxRows={1000}
                    required
                    onChange={(e) => setRecipe_name(e.target.value)}
                  />
                </div>
                <div className="texte">
                  <TextField
                    className="w-100"
                    label="Author"
                    variant="outlined"
                    margin="normal"
                    value={Author}
                    multiline
                    maxRows={1000}
                    onChange={(e) => setAuthor(e.target.value)}
                  />
                </div>
                <div className="texte">
                  <TextField
                    className="w-100"
                    type="number"
                    label="Nbpersonnes"
                    variant="outlined"
                    margin="normal"
                    value={nb_personnes}
                    onChange={(e) => setNb_personnes(e.target.value)}
                  />
                </div>
                <div className="texte">
                  <TextField
                    className="w-100"
                    type="url"
                    label=" Link"
                    variant="outlined"
                    margin="normal"
                    value={link}
                    multiline
                    maxRows={1000}
                    required
                    onChange={(e) => setLink(e.target.value)}
                  />
                </div>
              </div>
              <div className="row ">
                <div className="texte">
                  <TextField
                    className="w-100"
                    type="time in minute"
                    label="Preparation Time"
                    variant="outlined"
                    margin="normal"
                    value={preparationTime}
                    multiline
                    maxRows={1000}
                    onChange={(e) => setpreparationTime(e.target.value)}
                  />
                </div>
                <div className="texte">
                  <TextField
                    className="w-100"
                    type="time in minute"
                    label="Cooking Time"
                    variant="outlined"
                    margin="normal"
                    value={cooking_time}
                    multiline
                    maxRows={1000}
                    onChange={(e) => setCooking_time(e.target.value)}
                  />
                </div>
                <div className="texte">
                  <TextField
                    className="w-100"
                    type="time in minute"
                    label="Standing Time"
                    variant="outlined"
                    margin="normal"
                    value={standing_time}
                    multiline
                    maxRows={1000}
                    onChange={(e) => setStanding_time(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="texte">
                  <TextField
                    className="w-100"
                    type="text"
                    label="Steps"
                    variant="outlined"
                    margin="normal"
                    value={steps}
                    multiline
                    maxRows={1000}
                    required
                    onChange={(e) => setSteps(e.target.value)}
                  />
                </div>
                <div className="texte">
                  <TextField
                    className="w-100"
                    type="text"
                    label=" Ingredient Quantity"
                    variant="outlined"
                    margin="normal"
                    value={ingredients_quantities}
                    multiline
                    maxRows={1000}
                    required
                    onChange={(e) => setIngredients_quantities(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="selectitem">
                <div className="texte">
                  {recipe_type && (
                    <div style={{ marginTop: 20, lineHeight: "25px" }}>
                      <div>
                        <b>Type selectionné: </b>{" "}
                        {JSON.stringify(recipe_type, null, 2)}
                      </div>
                    </div>
                  )}
                  <Select
                    className="dropdown"
                    placeholder="Select Option"
                    value={recipeType.filter((obj) =>
                      recipe_type.includes(obj.value)
                    )} // set selected values
                    options={recipeType} // set list of the data
                    onChange={handleChange} // assign onChange function
                    isMulti
                    isClearable
                  />
                </div>
                {data && (
                  <div className="texte">
                    {Ingredients && (
                      <div style={{ marginTop: 20, lineHeight: "25px" }}>
                        <div>
                          {Ingredients.length !== 1 ? (
                            <b>Ingrédients selectionnés: </b>
                          ) : (
                            <b>Ingrédient selectionné: </b>
                          )}{" "}
                          {JSON.stringify(Ingredients, null, 2)}
                        </div>
                      </div>
                    )}
                    <Select
                      className="dropdown"
                      placeholder="Select Option"
                      value={data.filter((obj) =>
                        Ingredients.includes(obj.value)
                      )} // set selected values
                      options={data} // set list of the data
                      onChange={handleChangeIngredient} // assign onChange function
                      isMulti
                      isClearable
                      required
                    />
                  </div>
                )}
              </div>
              <div className="photo shadow p-1">
                {url_media && (
                  <img
                    className="imagephoto"
                    src={URL.createObjectURL(url_media)}
                    alt=""
                  />
                )}
                <div className="uploader">
                  <div className="upload">
                    <Button variant="contained" component="label">
                      Upload
                      <input
                        hidden
                        type="file"
                        name="url_media"
                        accept="image/*"
                        multiple
                        onChange={(e) => setMedia(e.target.files[0])}
                      />
                    </Button>
                  </div>
                  <div className="m-5">
                    <button onClick={handleDelete} className="btn12">
                      <DeleteIcon />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    control={
                      <Switch
                        defaultChecked={four}
                        onChange={(e) => setFour(e.target.checked)}
                      />
                    }
                    label="Four"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        defaultChecked={vegan}
                        onChange={(e) => setVegan(e.target.checked)}
                      />
                    }
                    label="Vegan"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        defaultChecked={gluten}
                        onChange={(e) => setGluten(e.target.checked)}
                      />
                    }
                    label="Gluten"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        defaultChecked={lactose}
                        onChange={(e) => setLactose(e.target.checked)}
                      />
                    }
                    label="Lactose"
                  />
                </FormGroup>
              </FormControl>
            </div>

            <Button
              variant="contained"
              color="success"
              type="submit"
              margin="normal"
            >
              Valider
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Createrecipe;
