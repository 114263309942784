import React from "react";
import { useEffect, useState } from "react";
import * as userServices from "../../../services/users"; 

const ChartUser = () => {
  const [nbrUser, setNbrUser] = useState();

  useEffect(()=>{
    userServices.getAllUsers()
    .then(users => {
      setNbrUser(users.length)
    })
    console.log(nbrUser)
  }, [nbrUser])

  return (
    <div className=" circle text-white text-center p-2">
      <div className="my-5">
      <h2>Utilisateurs</h2>
      <h1 className='display-5'>{nbrUser}</h1>
      </div>
    </div>
    );
}

export default ChartUser;
